import BaseModel from "@/models/BaseModel";
import OrderItem from "@/models/OrderItem";
import OrderStatus from "@/models/OrderStatus";
import Cart from "@/models/Cart";
import Currency from "@/models/Currency";
import Gift from "@/models/Gift";

export default class Order extends BaseModel {
  static entity = "orders";
  static modelPath = "orders";
  static formKey = "order";

  get getTotalGross() {
    return this.total_gross.toFixed(2);
  }

  get getTotalNet() {
    return this.total_net.toFixed(2);
  }

  get getTransportFeeGros() {
    return this.transport_fee_gros.toFixed(2);
  }

  static fields() {
    return {
      id: this.uid(),
      uid: this.uid(),
      customer_order_number: this.string("").nullable(),
      ip: this.string("").nullable(),
      cart_id: this.number(""),
      shop_id: this.number(""),
      gift_id: this.number(""),
      currency_id: this.number(""),
      total_net: this.number(""),
      product_total_gross: this.number(0),
      product_total_net: this.number(0),
      discount_value_gross: this.number(""),
      discount_value_net: this.number(""),
      payment_fee_gross: this.number(""),
      payment_fee_net: this.number(0),
      transport_fee_gross: this.number(""),
      transport_fee_net: this.number(""),
      total_gross: this.number(""),
      order_status_id: this.number(""),
      created_at: this.attr(),
      updated_at: this.attr(),
      cart: this.belongsTo(Cart, "cart_id"),
      currency: this.belongsTo(Currency, "currency_id"),
      order_items: this.hasMany(OrderItem, "order_id"),
      order_status: this.belongsTo(OrderStatus, "order_status_id"),
      gift: this.belongsTo(Gift, "gift_id")
    };
  }

  static fetchAll(shopId) {
    return this.api().get(`shops/${shopId}/${this.modelPath}`);
  }

  static getOrderQuery(shopId, data) {
    const config = {
      save: false
    };
    return this.api()
      .get(`shops/${shopId}/orders/query`, data, config)
      .then((response) => {
        const data = response.response.data;
        Order.deleteAll();
        Order.insert({ data: data.orders });
        return parseInt(data.meta.total_count);
      });
  }

  static downloadOrderXml(shopId, order) {
    return this.api().get(`shops/${shopId}/orders/${order.id}/download_optima_xml`).then((response) => {
      const data = response.response.data;
      const blob = new Blob([data], { type: data.type });
      const link = document.createElement("a");

      link.href = URL.createObjectURL(blob);
      link.download = "zamowienie_" + order.uid + "_" + order.created_at.toString() + ".xml";
      link.click();
      URL.revokeObjectURL(link.href);
    });
  }
}
