import baseModel from './BaseModel'

export default class EmailSetting extends baseModel {
  static entity = 'email_settings'
  static modelPath = 'email_settings'
  static formKey = 'email_setting'

  static fields() {
    return {
      id: this.uid(),
      name: this.string(''),
      email: this.string(''),
      description: this.string(''),
      user_name: this.string(''),
      server_name: this.string(''),
      authorization_configuration: this.attr({
        normal_password: '',
        encrypted_password: '',
        oauth_token: '',
      }),
      auth_method: this.string(''),
      smtp_port: this.string(''),
      connection_security: this.string(''),
    }
  }

  static getAuthMethods() {
    return [
      { name: 'Zwykłe hasło', value: 'normal_password_auth' },
      { name: 'Zaszyfrowane hasło', value: 'encrypted_password_auth' },
      { name: 'OAuth2', value: 'oauth2' },
    ]
  }

  static getConnectionSecurity() {
    return [
      { name: 'Szyfrowanie SSL/TLS', value: 'ssl_tls' },
      { name: 'Szyfrowanie STARTTLS', value: 'starttls' },
      { name: 'Bez zabezpieczeń', value: 'unsecure' },
    ]
  }

  static getSmtpPort() {
    return [
      {
        name: '587 - domyślny dla połączeń nieszyfrowanych',
        value: 'port_587',
      },
      {
        name: '25 - alternatywny dla połączeń nieszyfrowanych',
        value: 'port_25',
      },
      { name: '465 - domyślny dla połaczeń szyfrowanych', value: 'port_465' },
      { name: '2525 - alternatywny port', value: 'port_2525' },
    ]
  }

  static sendTestEmail(shopId, id, email) {
    const data = {
      email_setting: {
        test_email: email,
      },
    }
    return this.api().post(
      `shops/${shopId}/email_settings/${id}/send_test_email`,
      data
    )
  }
}
