import CategoryFeature from "./CategoryFeature";
import FeatureListValue from "@/models/FeatureListValue";
import BaseModel from "@/models/BaseModel";

export class Feature extends BaseModel {
  static entity = "features";
  static modelPath = "features";
  static formKey = "feature";

  static types() {
    return {
      FEATURE: Feature,
      FEATURE_LIST: FeatureList,
      FEATURE_NUMBER: FeatureNumber,
      FEATURE_STRING: FeatureString
    };
  }

  static fields() {
    return {
      id: this.uid(),
      shop_id: this.number(0),
      name: this.string(""),
      comment: this.string(""),
      type: this.string(""),
      category_features: this.hasMany(CategoryFeature, "feature_id")
    };
  }

  static create(shopId, featureForm) {
    const data = {
      feature: featureForm
    };
    return this.api().post(`shops/${shopId}/features`, data);
  }

  static remove(shopId, featureId) {
    return this.api().delete(`/shops/${shopId}/features/${featureId}`, {
      delete: featureId
    });
  }

  static getById(shopId, featureId) {
    return this.api().get(`shops/${shopId}/features/${featureId}`);
  }

  static setById(shopId, featureId, featureForm) {
    return this.api().put(
      `shops/${shopId}/features/${featureId}`,
      featureForm
    );
  }

  static countProducts(shopId, featureId) {
    const config = {
      save: false
    };
    return this.api().get(`shops/${shopId}/features/${featureId}/products_count`, config).then(({ response }) => {
      return response?.data?.products_count || 0;
    });
  }


}

export class FeatureList extends Feature {
  static entity = "feature_lists";

  // Reference to base entity name.
  static baseEntity = "features";

  // Call `super.fields()`` to merge fields.
  static fields() {
    return {
      ...super.fields(),
      feature_list_values: this.hasMany(FeatureListValue, "feature_id")
    };
  }

  static create(shopId, featureForm) {
    const data = {
      feature_list: featureForm
    };
    return this.api().post(`shops/${shopId}/feature_lists`, data);
  }

  static setById(activeShopId, featureId, featureForm) {
    return this.api().put(
      `shops/${activeShopId}/feature_lists/${featureId}`,
      featureForm
    );
  }

  static getById(shopId, featureId) {
    return this.api().get(`shops/${shopId}/feature_lists/${featureId}`);
  }

  static apiConfig = {
    actions: {
      fetchAll(shopId) {
        return this.get(`/shops/${shopId}/feature_list`);
      }
    }
  };
}

export class FeatureNumber extends Feature {
  static entity = "feature_numbers";

  // Reference to base entity name.
  static baseEntity = "features";

  // Call `super.fields()`` to merge fields.
  static fields() {
    return {
      ...super.fields()
      // job: this.attr('')
    };
  }

  static create(shopId, featureForm) {
    const data = {
      feature_number: featureForm
    };
    return this.api().post(`shops/${shopId}/feature_numbers`, data);
  }

  static setById(activeShopId, featureId, featureForm) {
    return this.api().put(
      `shops/${activeShopId}/feature_numbers/${featureId}`,
      featureForm
    );
  }

  static apiConfig = {
    actions: {
      fetchAll(shopId) {
        return this.get(`/shops/${shopId}/feature_numbers`);
      }
    }
  };
}

export class FeatureString extends Feature {
  static entity = "feature_strings";

  // Reference to base entity name.
  static baseEntity = "features";

  // Call `super.fields()`` to merge fields.
  static fields() {
    return {
      ...super.fields()
      // job: this.attr('')
    };
  }

  static create(shopId, featureForm) {
    const data = {
      feature_string: featureForm
    };
    return this.api().post(`shops/${shopId}/feature_strings`, data);
  }

  static setById(activeShopId, featureId, featureForm) {
    return this.api().put(
      `shops/${activeShopId}/feature_strings/${featureId}`,
      featureForm
    );
  }

  static apiConfig = {
    actions: {
      fetchAll(shopId) {
        return this.get(`/shops/${shopId}/feature_strings`);
      }
    }
  };
}
