import BaseModel from "./BaseModel";
import CategoryFeature from "./CategoryFeature";

export default class Category extends BaseModel {
  static entity = "categories";
  static modelPath = "categories";
  static formKey = "category";

  static fields() {
    return {
      id: this.uid(),
      name: this.string(""),
      description: this.string(""),
      description_extended: this.string("").nullable(),
      slug: this.string(""),
      image_path: this.string(""),
      visible: this.boolean(false),
      parent_id: this.number(null).nullable(),
      position: this.number(""),
      parent: this.belongsTo(Category, "parent_id"),
      category_features: this.hasMany(CategoryFeature, "category_id")
    };
  }

  get imageExists() {
    return (
      this.image_path !== undefined &&
      this.image_path !== null &&
      this.image_path.length > 0
    );
  }

  get getImageUrl() {
    if (this.image_path) {
      return this.baseImageUrl + this.image_path;
    } else {
      return "";
    }
  }

  get baseImageUrl() {
    return this.$store().getters["shop/imageBaseUrl"];
  }

  static changePosition(shopId, data) {
    const putData = {
      position: data.position,
      dst_category_id: data.dstCategoryId
    };
    return this.api().put(
      `/shops/${shopId}/categories/${data.srcCategoryId}/position`,
      putData
    );
  }
}
