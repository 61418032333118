
import { mapActions } from "vuex";
import Shop from "@/models/Shop";

export default {
  name: "PanelHeader",
  computed: {
    shops() {
      return Shop.all();
    }
  },
  created() {
  },
  methods: {
    ...mapActions({
      logUserOut: "shopAuth/logUserOut"
    }),
    signOutUser() {
      this.logUserOut();
    }
  }
};
