import BaseModel from '@/models/BaseModel'

export default class BannerSlider extends BaseModel {
  static entity = 'banner_sliders'
  static formKey = 'banner_slider'
  static modelPath = 'banner_sliders'

  static fields() {
    return {
      id: this.uid(),
      title: this.string(''),
      alt: this.string(''),
      link: this.string(''),
      image_path: this.string(''),
      is_active: this.boolean(true),
      position: this.number(0),
    }
  }

  get getImageUrl() {
    if (this.image_path) {
      return this.baseImageUrl + this.image_path
    } else {
      return ''
    }
  }

  get baseImageUrl() {
    return this.$store().getters['shop/imageBaseUrl']
  }

  get getEditLink() {
    return `/settings/slider/${this.id}`
  }

  static setPosition(shopId, sliderId, position) {
    const data = {
      banner_slider: {
        position,
      },
    }
    return this.api().put(
      `/shops/${shopId}/banner_sliders/${sliderId}/position`,
      data
    )
  }
}
