import BaseModel from '@/models/BaseModel'

export default class LocalBusiness extends BaseModel {
  static entity = 'local_businesses'
  static formKey = 'local_business'
  static modelPath = 'local_businesses'

  static fields() {
    return {
      id: this.uid(),
      name: this.string(''),
      description: this.string(''),
      street: this.string(''),
      region: this.string(''),
      postal_code: this.string(''),
      phone: this.string(''),
      url: this.string(''),
      same_as: this.string(''),
      has_map: this.string(''),
      opening_hours: this.attr({}),
      price_range: this.string(''),
      image: this.string(''),
      is_active: this.boolean(false),
    }
  }

  get getImageUrl() {
    return this.image ? this.baseImageUrl + this.image : ''
  }

  get baseImageUrl() {
    return this.$store().getters['shop/imageBaseUrl']
  }
}
