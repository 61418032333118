import Product from "./Product";
import BaseModel from "@/models/BaseModel";

export default class NewProduct extends BaseModel {
  static entity = "new_products";
  static modelPath = "new_products";
  static formKey = "new_product";


  static fields() {
    return {
      id: this.uid(),
      product_id: this.number(0),
      position: this.number(0),
      product: this.belongsTo(Product, "product_id")
    };
  }

  static remove(shopId, newProductId) {
    return this.api().delete(
      `/shops/${shopId}/new_products/${newProductId}`,
      {
        delete: newProductId
      }
    );
  }

  static add(shopId, productId) {
    const data = {
      new_product: {
        product_id: productId
      }
    };

    return this.api().post(
      `/shops/${shopId}/new_products`,
      data
    );

  }

  static setById(shopId, newProductId, form) {
    const data = {
      product_bestseller: form
    };
    return this.api().put(
      `/shops/${shopId}/new_products/${newProductId}`,
      data
    );
  }


  static setPosition(shopId, newProductId, position) {
    const data = {
      new_product: {
        position
      }
    };
    return this.api().put(
      `/shops/${shopId}/new_products/${newProductId}/set_position`,
      data
    );
  }

}
