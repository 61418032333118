import { Model } from '@vuex-orm/core'
import { keysToSnake } from '@/helpers/caseConverter'
import CartItem from '@/models/CartItem'
import SellDocumentType from '@/models/SellDocumentType'
import { BillingAddress, DeliveryAddress } from '@/models/CustomerAddress'
import { Component } from '@/models/ComponentHierarchy'
import DeliveryPickUpPoint from '@/models/DeliveryPickUpPoint'
import Customer from '@/models/Customer'

export default class Cart extends Model {
  static entity = 'carts'
  static modelPath = 'carts'
  static formKey = 'cart'
  static apiConfig = {
    actions: {
      getActive() {
        return this.get('cart').then(({ response }) => {
          const activeCartId =
            response && response?.data?.id ? response.data.id : false
          if (activeCartId) {
            Cart.delete((cart) => cart.id !== activeCartId)
            CartItem.delete((cartItem) => cartItem.cart_id !== activeCartId)
          }
        })
      },
    },
  }

  get getProductCount() {
    return CartItem.query().where('cart_id', this.id).sum('items')
  }

  get isEmpty() {
    return this.cart_items.length === 0
  }

  get productsTotalValue() {
    let totalValue = 0
    this.cart_items.forEach(
      (ci) => (totalValue += ci.items * ci.product.getFinalPrice)
    )
    return totalValue
  }

  get getDeliveryAddress() {
    // console.log('Cart.getDeliveryAddress')
    if (this.enable_delivery_address && this.delivery_address_id !== null) {
      return this.delivery_address
    }
    if (this.billing_address_id !== null) {
      return this.billing_address
    }
    return null
  }

  get deliveryValue() {
    const deliveryAddress = this.getDeliveryAddress
    const componentDeliveryMethod = this.component_delivery_method
    // console.log('Cart.deliveryValue')
    // console.log(deliveryAddress)
    // console.log(componentDeliveryMethod)
    if (deliveryAddress && componentDeliveryMethod) {
      return componentDeliveryMethod.getPrice()
    }

    return 0
  }

  // TODO rename deliveries to delivery_method to delivery
  static fields() {
    return {
      id: this.uid(),
      customer_id: this.number(null).nullable(),
      discount_id: this.number(null),
      finalized: this.boolean(false),
      component_payment_method_id: this.number(null),
      component_delivery_method_id: this.number(null),

      billing_address_id: this.number(null),
      delivery_address_id: this.number(null),
      delivery_pick_up_point_id: this.number(null),
      customer_notes: this.string(null),
      discount_code: this.string(null),
      sell_document_type_id: this.number(),
      enable_delivery_address: this.boolean(false),
      use_pick_up_point: this.boolean(false),
      customer: this.belongsTo(Customer, 'customer_id'),
      cart_items: this.hasMany(CartItem, 'cart_id'),
      sell_document_type: this.belongsTo(
        SellDocumentType,
        'sell_document_type_id'
      ),
      component_delivery_method: this.belongsTo(
        Component,
        'component_delivery_method_id'
      ),
      delivery_address: this.belongsTo(DeliveryAddress, 'delivery_address_id'),
      billing_address: this.belongsTo(BillingAddress, 'billing_address_id'),
      delivery_pick_up_point: this.belongsTo(
        DeliveryPickUpPoint,
        'delivery_pick_up_point_id'
      ),
    }
  }

  static addItem(formData) {
    const data = {}
    data.cart_item = keysToSnake(formData)
    return this.api().post('cart/cart_items/', data)
  }

  static setPaymentMethod(componentPaymentMethodId) {
    const data = {
      cart: {
        component_payment_method_id: componentPaymentMethodId,
      },
    }
    return this.api().put('cart/set_payment/', data)
  }

  static setDeliveryMethod(componentDeliveryMethodId) {
    const data = {
      cart: {
        component_delivery_method_id: componentDeliveryMethodId,
      },
    }
    return this.api().put('cart/set_delivery/', data)
  }

  static setPickUpPoint(pickUpPoint) {
    const data = {
      cart: {
        pick_up_point: pickUpPoint,
      },
    }
    return this.api().put('cart/set_pick_up_point/', data)
  }

  static setSellDocumentType(sellDocumentTypeId) {
    const data = {
      cart: {
        sell_document_type_id: sellDocumentTypeId,
      },
    }
    return this.api().put('cart/set_sell_document_type/', data)
  }

  static enableDeliveryAddress() {
    return this.api().put('cart/delivery_address/enable')
  }

  static disableDeliveryAddress() {
    return this.api().put('cart/delivery_address/disable')
  }

  deliveryMethodName() {
    const componentDeliveryMethod = this.component_delivery_method
    if (componentDeliveryMethod) {
      return componentDeliveryMethod.name
    } else {
      return 'Wybierz metodę dostawy'
    }
  }
}
