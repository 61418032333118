import basicModel from '@/models/BaseModel'
import Cart from '@/models/Cart'
import Product from '@/models/Product'
import { keysToSnake } from '@/helpers/caseConverter'

export default class CartItem extends basicModel {
  static entity = 'cart_items'
  static modelPath = 'cart_items'
  static formKey = 'cart_item'

  get getItemValue() {
    return this.items * this.product.getFinalPrice
  }

  static fields() {
    return {
      id: this.uid(),
      cart_id: this.number(null),
      product_id: this.number(null),
      items: this.number(null),
      cart: this.belongsTo(Cart, 'cart_id'),
      product: this.belongsTo(Product, 'product_id'),
    }
  }

  static create(formData) {
    const data = {}
    data[this.formKey] = keysToSnake(formData)
    return this.api().post(`cart/${this.modelPath}/`, data)
  }

  static removeCartItem(ci) {
    return this.api().delete(`cart/cart_items/${ci}`, {
      delete: ci,
    })
  }

  static incrementCartItem(ci) {
    return this.api().put(`cart/cart_items/${ci}/inc`)
  }

  static decrementCartItem(ci) {
    return this.api().put(`cart/cart_items/${ci}/dec`)
  }
}
