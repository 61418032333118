import BaseModel from './BaseModel'
import { Component } from '@/models/ComponentHierarchy.js'

export default class ConfigurationScopeComponent extends BaseModel {
  static entity = 'configuration_scope_components'
  static modelPath = 'configuration_scope_components'
  static formKey = 'configuration_scope_component'

  static fields() {
    return {
      id: this.uid(),
      configuration_scope_id: this.number(),
      component_id: this.number(),
      component: this.belongsTo(Component, 'component_id'),
    }
  }

  static fetchAll() {
    return this.api()
      .get(`${this.modelPath}`)
      .catch((e) => {
        return e
      })
  }
}
