import { Component } from '@/models/ComponentHierarchy.js'

export class ComponentPaymentCod extends Component {
  static entity = 'component_payments'
  static baseEntity = 'components'

  static fields() {
    return {
      ...super.fields(),
      type: this.attr('ComponentPaymentCod'),
    }
  }

  getPrice() {
    return 0
  }
}
