import Product from "./Product";
import BaseModel from "@/models/BaseModel";

export default class RelatedProduct extends BaseModel {
  static entity = "related_products";
  static modelPath = "related_products";
  static formKey = "related_product";


  static fields() {
    return {
      id: this.uid(),
      product_id: this.number(0),
      position: this.number(0),
      related_product_id: this.number(0),
      product: this.belongsTo(Product, "product_id"),
      related_product: this.belongsTo(Product, "related_product_id")
    };
  }

  static remove(shopId, productId, relatedProductId) {
    return this.api().delete(
      `/shops/${shopId}/products/${productId}/related_products/${relatedProductId}`,
      {
        delete: relatedProductId
      }
    );
  }

  static add(shopId, productId, relatedProductId) {
    const data = {
      related_product: {
        related_product_id: relatedProductId
      }
    };

    return this.api().post(
      `/shops/${shopId}/products/${productId}/related_products`,
      data
    );

  }

  static setById(shopId, productId, relatedProductId, form) {
    const data = {
      related_product: form
    };
    return this.api().put(
      `/shops/${shopId}/products/${productId}/related_products/${relatedProductId}`,
      data
    );
  }


  static setPosition(shopId, productId, relatedProductId, position) {
    const data = {
      related_product: {
        position
      }
    };
    return this.api().put(
      `/shops/${shopId}/products/${productId}/related_products/${relatedProductId}/set_position`,
      data
    );
  }


  static apiConfig = {
    actions: {
      fetchForProductId(shopId, productId) {
        return this.get(
          `/shops/${shopId}/products/${productId}/related_products`
        );
      }
    }
  };
}
