import BaseModel from "@/models/BaseModel";
import Customer from "@/models/Customer";
import Country from "@/models/Country";

export class CustomerAddress extends BaseModel {
  static entity = "customer_addresses";
  static modelPath = "customer/customer_addresses";
  static formKey = "customer_address";

  get customerName() {
    return this.is_company
      ? this.company_name
      : `${this.first_name} ${this.last_name}`;
  }

  static types() {
    return {
      CUSTOMER: CustomerAddress,
      BILLING: BillingAddress,
      DELIVERY: DeliveryAddress
    };
  }

  static fields() {
    return {
      id: this.uid(null),
      label: this.string("").nullable(),
      customer_id: this.number(null).nullable(),
      is_company: this.boolean(false).nullable(),
      company_name: this.string("").nullable(),
      company_vat_no: this.string("").nullable(),
      first_name: this.string("").nullable(),
      last_name: this.string("").nullable(),
      country_id: this.number("").nullable(),
      region: this.string("").nullable(),
      street_no: this.string("").nullable(),
      city: this.string("").nullable(),
      post_code: this.string("").nullable(),
      contact_phone: this.string("").nullable(),
      notes: this.string("").nullable(),
      data_valid: this.boolean("").nullable(),
      customer: this.belongsTo(Customer, "customer_id"),
      country: this.belongsTo(Country, "country_id")
    };
  }

  static create(shopId, customerId, form) {
    const data = {
      customer_address: form
    };
    return this.api().post(`/shops/${shopId}/customer_addresses`, data);
  }

  static setById(shopId, customerId, addressId, form) {
    const data = {
      customer_address: form
    };
    return this.api().put(`/shops/${shopId}/customers/${customerId}/customer_addresses/${addressId}`, data);
  }


}

export class BillingAddress extends CustomerAddress {
  static entity = "billing_addresses";
  static baseEntity = "customer_addresses";

  static fields() {
    return {
      ...super.fields(),
      type: this.attr("BillingAddress")
    };
  }
}

export class DeliveryAddress extends CustomerAddress {
  static entity = "delivery_addresses";
  static baseEntity = "customer_addresses";

  static fields() {
    return {
      ...super.fields(),
      type: this.attr("DeliveryAddress")
    };
  }
}
