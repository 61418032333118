import BaseModel from '@/models/BaseModel'

export default class Address extends BaseModel {
  static entity = 'addresses'
  static modelPath = 'addresses'
  static formKey = 'address'

  static fields() {
    return {
      id: this.uid(),
      label: this.string(''),
      country_id: this.number(),
      region: this.string(''),
      location: this.string(''),
      city: this.string(''),
      post_code: this.string(''),
      street: this.string(''),
    }
  }
}
