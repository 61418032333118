import VatTax from "@/models/VatTax";
import Currency from "@/models/Currency";
import Shop from "@/models/Shop";
import Category from "@/models/Category";
// import Product from '@/models/Product'
import { Feature } from "@/models/Feature";
import ConfigurationScope from "@/models/ConfigurationScope";
import ShopConfiguration from "@/models/ShopConfiguration";
import ConfigurationScopeComponent from "@/models/ConfigurationScopeComponent";
import OrderStatus from "@/models/OrderStatus";
import Brand from "@/models/Brand";
import FeatureListValue from "@/models/FeatureListValue";


export const state = () => ({
  componentsLoaded: [
    { component: "VatTax", isLoaded: false },
    { component: "Currency", isLoaded: false },
    { component: "Shop", isLoaded: false },
    { component: "Category", isLoaded: false },
    { component: "Brand", isLoaded: false },
    // { component: 'Product', isLoaded: false },
    { component: "Feature", isLoaded: false },
    { component: "FeatureListValue", isLoaded: false },
    { component: "ConfigurationScope", isLoaded: false },
    { component: "ShopConfiguration", isLoaded: false },
    { component: "ConfigurationScopeComponent", isLoaded: false },
    { component: "OrderStatus", isLoaded: false }
  ],
  activeShopId: 1,
  unsavedObjectData: false, // tablica obiektów
  imageBaseUrl: process.env.IMAGE_BASE_URL,
  shopBaseUrl: process.env.SHOP_BASE_URL,
  productionTest: process.env.PRODUCTION_TEST
});

export const getters = {
  unsavedObjectData: (state) => {
    return state.unsavedObjectData;
  },
  imageBaseUrl: (state) => {
    return state.imageBaseUrl;
  },
  shopBaseUrl: (state) => {
    return state.shopBaseUrl;
  },
  productionTest: (state) => {
    return state.productionTest === "true";
  },
  apiDataLoaded: (state) => {
    return state.componentsLoaded.every((item) => item.isLoaded === true);
  },
  activeShopId: () => {
    return 1;
  },
  rootCategoryId: (state) => {
    let rootCatId = 0;
    const activeShop = Shop.find(state.activeShopId);
    if (activeShop) {
      rootCatId = activeShop.root_category_id;
    }
    return rootCatId;
  },
  categoryTreeMain: (state, getters) => {
    const categories = Category.query().orderBy("position").get();
    return [
      {
        text: "Home",
        id: getters.rootCategoryId,
        state: { expanded: true, dropable: false },
        children: categories
          .filter((item) => item.parent_id === getters.rootCategoryId)
          .map((item) => ({
            text: item.name,
            id: item.id,
            children: getters.categoryTree2(categories, item.id)
          }))
      }
    ];
  },
  categoryTree2:
    (state, getters) =>
      (categories, id = null) => {
        return categories
          .filter((item) => item.parent_id === id)
          .map((item) => ({
            text: item.name,
            id: item.id,
            children: getters.categoryTree2(categories, item.id)
          }));
      },
  categoryTreeForSelect: (state, getters) => {
    const categories = Category.query()
      .orderBy("position")

      .get();
    const categoryList = [];
    categoryList.push({
      name: "Home",
      id: getters.rootCategoryId
    });
    getters.categoryTreeForSelectRecurent(
      categories,
      categoryList,
      0,
      getters.rootCategoryId
    );
    return categoryList;
  },
  categoryTreeForSelectRecurent:
    (state, getters) =>
      (categories, finalList, level = 0, parentId = null) => {
        const categoryArray = categories.filter(
          (item) => item.parent_id === parentId
        );
        categoryArray.forEach((item) => {
          finalList.push({ name: "-".repeat(level + 1) + item.name, id: item.id });
          getters.categoryTreeForSelectRecurent(
            categories,
            finalList,
            level + 1,
            item.id
          );
        });
      }
};

export const mutations = {
  STATE_INIT(state, payload) {
    const item = state.componentsLoaded.find(
      (item) => item.component === payload.component
    );
    item.isLoaded = payload.isLoaded;
  },
  CLEAR_MODELS(state) {
    state.componentsLoaded.forEach((item) => (item.isLoaded = false));
  },
  CHANGE_UNSAVED_OBJECT_DATA(state, payload) {
    state.unsavedObjectData = payload;
  }
};
export const actions = {
  setUnsavedObjectData({ commit, getters }, payload) {
    commit("CHANGE_UNSAVED_OBJECT_DATA", payload);
  },
  async initState({ commit, getters }) {
    // console.log('this.getters.apiDataLoaded')
    // console.log(getters.apiDataLoaded)
    if (getters.apiDataLoaded) return null;
    // console.log('load init data')
    return await Promise.all([
      VatTax.fetchAll(1).then(() =>
        commit("STATE_INIT", { component: "VatTax", isLoaded: true })
      ),
      Currency.fetchAll(1).then(() =>
        commit("STATE_INIT", { component: "Currency", isLoaded: true })
      ),
      Shop.api()
        .fetchAll()
        .then(() =>
          commit("STATE_INIT", { component: "Shop", isLoaded: true })
        ),
      // load brand
      Brand.fetchAll(1).then(() =>
        commit("STATE_INIT", { component: "Brand", isLoaded: true })
      ),
      Category.fetchAll(1).then(() =>
        commit("STATE_INIT", { component: "Category", isLoaded: true })
      ),
      // Product.api()
      //   .fetchAll(1)
      //   .then(() =>
      //     commit('STATE_INIT', { component: 'Product', isLoaded: true })
      //   ),
      Feature.fetchAll(1)
        .then(() =>
          commit("STATE_INIT", { component: "Feature", isLoaded: true })
        ),
      FeatureListValue.fetchAll(1).then(() =>
        commit("STATE_INIT", { component: "FeatureListValue", isLoaded: true })
      ),
      ConfigurationScope.fetchAll().then(() =>
        commit("STATE_INIT", {
          component: "ConfigurationScope",
          isLoaded: true
        })
      ),
      ShopConfiguration.fetchAll(1).then(() =>
        commit("STATE_INIT", {
          component: "ShopConfiguration",
          isLoaded: true
        })
      ),
      ConfigurationScopeComponent.fetchAll().then(() =>
        commit("STATE_INIT", {
          component: "ConfigurationScopeComponent",
          isLoaded: true
        })
      ),
      OrderStatus.fetchAll().then(() => {
        commit("STATE_INIT", {
          component: "OrderStatus",
          isLoaded: true
        });
      })
    ]);
  },
  clearStore({ commit, dispatch }) {
    dispatch("entities/deleteAll", {}, { root: true });
    commit("CLEAR_MODELS");
  }
};
