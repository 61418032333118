import { Model } from '@vuex-orm/core'

export default class CmsBlockType extends Model {
  static entity = 'cms_block_types'

  static fields() {
    return {
      id: this.number(),
      name: this.string(''),
    }
  }

  static fetchAll() {
    return this.api().get('/cms_block_types')
  }
}
