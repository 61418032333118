import BaseModel from "@/models/BaseModel";

export default class Employee extends BaseModel {
  static entity = "employees";
  static modelPath = "employees";
  static formKey = "employee";

  static fields() {
    return {
      id: this.uid(),
      email: this.string(""),
      name: this.string(""),
      last_name: this.string(""),
      phone: this.string(""),
      role: this.string(""),
      image_path: this.string("")
    };
  }

  get imageExists() {
    return (
      this.image_path !== undefined &&
      this.image_path !== null &&
      this.image_path.length > 0
    );
  }

  get baseImageUrl() {
    return this.$store().getters["shop/imageBaseUrl"];
  }

  get getImageUrl() {
    if (this.image_path) {
      return this.baseImageUrl + this.image_path;
    } else {
      return "";
    }
  }

  static apiConfig = {
    actions: {}
  };
}
