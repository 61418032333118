import { Model } from "@vuex-orm/core";
import moment from "moment";
import { keysToSnake } from "@/helpers/caseConverter";

export default class BaseModel extends Model {
  static entity = "";
  static modelPath = "";
  static formKey = "";

  get baseImageUrl() {
    return this.$store().getters["shop/imageBaseUrl"];
  }

  get shopBaseUrl() {
    return this.$store().getters["shop/shopBaseUrl"];
  }

  static fields() {
    return {
      id: this.uid()
    };
  }

  static fetchAll(shopId) {
    return this.api().get(`shops/${shopId}/${this.modelPath}`);
  }

  static getById(shopId, id) {
    return this.api().get(`shops/${shopId}/${this.modelPath}/${id}`);
  }

  static create(shopId, form) {
    const data = {};
    data[this.formKey] = keysToSnake(form);
    return this.api().post(`shops/${shopId}/${this.modelPath}/`, data);
  }

  static createMultipart(shopId, form, progressHandler = undefined) {
    const formData = this.createMultipartForm(form);
    const axiosConfig = this.createMultipartFormAxiosConfig(progressHandler);
    return this.api().post(
      `/shops/${shopId}/${this.modelPath}`,
      formData,
      axiosConfig
    );
  }

  static setById(shopId, id, form) {
    const data = {};
    data[this.formKey] = keysToSnake(form);
    console.log(data);
    return this.api().put(`shops/${shopId}/${this.modelPath}/${id}`, data);
  }

  static setByIdMultipart(shopId, id, form, progressHandler = undefined) {
    const formData = this.createMultipartForm(form);

    const axiosConfig = this.createMultipartFormAxiosConfig(progressHandler);
    return this.api().put(
      `shops/${shopId}/${this.modelPath}/${id}`,
      formData,
      axiosConfig
    );
  }

  static remove(shopId, id) {
    return this.api().delete(`shops/${shopId}/${this.modelPath}/${id}`, {
      delete: id
    });
  }

  // https://github.com/smnscp/vuex-orm-plugin-date-attribute
  static mutators() {
    return {
      created_at(value) {
        return moment(value).format("YYYY-MM-DD HH:mm:ss");
      },
      updated_at(value) {
        return moment(value).format();
      }
    };
  }

  static createMultipartForm(form) {
    const formData = new FormData();
    // console.log(form)
    for (const formField in form) {
      // check for nested data
      const fieldName = this.formKey + "[" + formField + "]";
      const fieldValue = form[formField];
      this.processFormField(fieldName, fieldValue, formData);
    }
    // for (const key of formData.keys()) {
    //   console.log(key)
    // }

    return formData;
  }

  static processFormField(fieldName, fieldValue, formData) {
    switch (typeof fieldValue) {
      case "object":
        if (fieldValue instanceof File) {
          formData.append(fieldName, fieldValue);
        } else if (Array.isArray(fieldValue)) {
          this.processFormArray(fieldName, fieldValue, formData);
        } else {
          this.processFormObject(fieldName, fieldValue, formData);
        }
        break;
      case "string":
      case "boolean":
        formData.append(fieldName, fieldValue);
        break;

      default:
        if (fieldValue !== undefined && fieldValue !== null) {
          formData.append(fieldName, fieldValue);
        }
    }
  }

  static processFormObject(fieldName, fieldValue, formData) {
    if (fieldValue) {
      for (const [key, value] of Object.entries(fieldValue)) {
        const formKey = fieldName + "[" + key + "]";
        this.processFormField(formKey, value, formData);
      }
    }
  }

  static processFormArray(fieldName, fieldValue, formData) {
    if (fieldValue) {
      const formKey = fieldName + "[]";
      fieldValue.forEach((val) => {
        if (typeof val === "object") {
          // process object values
          this.processFormObject(formKey, val, formData);
        } else {
          // process simple values
          formData.append(formKey, val);
        }
      });
    }
  }

  static createMultipartFormAxiosConfig(progressHandler) {
    const config = {};
    config.headers = { "Content-Type": "multipart/form-data" };
    if (progressHandler !== undefined) {
      config.onUploadProgress = progressHandler;
    }
    return config;
  }
}
