import { Model } from '@vuex-orm/core'
import Product from './Product'

export default class ProductPicture extends Model {
  static entity = 'product_pictures'

  static fields() {
    return {
      id: this.uid(),
      product_id: this.number(0),
      position: this.number(0),
      is_cover: this.boolean(false),
      alt: this.string(''),
      title: this.string(''),
      url: this.string(''),
      product: this.belongsTo(Product, 'product_id'),
    }
  }

  static remove(shopId, productId, productPictureId) {
    return this.api().delete(
      `/shops/${shopId}/products/${productId}/product_pictures/${productPictureId}`,
      {
        delete: productPictureId,
      }
    )
  }

  static setById(shopId, productId, productPictureId, form) {
    const data = {
      product_picture: form,
    }
    return this.api().put(
      `/shops/${shopId}/products/${productId}/product_pictures/${productPictureId}`,
      data
    )
  }

  static setIsCoverById(shopId, productId, productPictureId) {
    const form = { is_cover: true }
    return this.setById(shopId, productId, productPictureId, form)
  }

  static setPosition(shopId, productId, productPictureId, position) {
    const data = {
      product_picture: {
        position,
      },
    }
    return this.api().put(
      `/shops/${shopId}/products/${productId}/product_pictures/${productPictureId}/set_position`,
      data
    )
  }

  static setAllByProductId(shopId, productId, form) {
    const data = {
      product_picture: form,
    }
    return this.api().put(
      `/shops/${shopId}/products/${productId}/product_pictures/set_alt_title_for_product_images`,
      data
    )
  }

  get baseImageUrl() {
    return this.$store().getters['shop/imageBaseUrl']
  }

  static apiConfig = {
    actions: {
      fetchForProductId(shopId, productId) {
        return this.get(
          `/shops/${shopId}/products/${productId}/product_pictures`
        )
      },
      upload(shopId, productId, pictures, progressHandlerr) {
        const formData = new FormData()
        for (let i = 0; i < pictures.length; i++) {
          const file = pictures[i]
          formData.append('product_pictures[]', file)
        }
        const config = {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: progressHandlerr,
        }
        return this.post(
          `/shops/${shopId}/products/${productId}/product_pictures`,
          formData,
          config
        )
      },
    },
  }
}
