import BaseModel from './BaseModel'

export default class ProductNotificationKind extends BaseModel {
  static entity = 'product_notification_kind'
  static modelPath = 'product_notification_kinds'
  static formKey = 'product_notification_kind'

  static fields() {
    return {
      id: this.uid(),
      name: this.string(''),
      symbol: this.string(''),
      description: this.string(''),
    }
  }

  static fetchAll() {
    return this.api().get(this.modelPath)
  }
}
