export function toCamel(s) {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

export function toPascal(str) {
  const s = toCamel(str)
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function isArray(a) {
  return Array.isArray(a)
}

export function isObject(o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function'
}

export function keysToCamel(o) {
  if (isObject(o)) {
    const n = {}
    Object.keys(o).forEach((k) => {
      n[toCamel(k)] = keysToCamel(o[k])
    })
    return n
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToCamel(i)
    })
  }
  return o
}

export function toSnake(s) {
  return s
    .replace(/([a-z])([A-Z])/g, ($0, $1, $2) => {
      return $1 + '_' + $2
    })
    .toLowerCase()
}

export function keysToSnake(o) {
  if (isObject(o)) {
    const n = {}
    Object.keys(o).forEach((k) => {
      n[toSnake(k)] = keysToSnake(o[k])
    })
    return n
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToSnake(i)
    })
  }
  return o
}
