import { Component } from '@/models/ComponentHierarchy.js'

export class ComponentDeliveryDpd extends Component {
  static entity = 'component_delivery'
  static baseEntity = 'components'

  static fields() {
    return {
      ...super.fields(),
      type: this.attr('ComponentDeliveryDpd'),
    }
  }

  getPrice() {
    return 3
  }
}
