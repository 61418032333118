import { Model } from '@vuex-orm/core'
import CmsBlock from '~/models/CmsBlock'
import Shop from '~/models/Shop'

export default class CmsPage extends Model {
  static entity = 'cms_pages'

  static fields() {
    return {
      id: this.number(),
      shop_id: this.number(),
      title: this.string(''),
      slug: this.string(''),
      meta_title: this.string(''),
      meta_description: this.string(''),
      cms_blocks: this.hasMany(CmsBlock, 'cms_page_id'),
      shop: this.belongsTo(Shop, 'shop_id'),
    }
  }

  static create(shopId, form) {
    const data = {
      cms_page: form,
    }
    return this.api().post(`/shops/${shopId}/cms_pages`, data)
  }

  static getById(shopId, cmsPageId) {
    return this.api().get(`shops/${shopId}/cms_pages/${cmsPageId}`)
  }

  static setById(shopId, cmsPageId, form) {
    return this.api().put(`shops/${shopId}/cms_pages/${cmsPageId}`, form)
  }

  static remove(shopId, cmsPageId) {
    return this.api().delete(`/shops/${shopId}/cms_pages/${cmsPageId}`, {
      delete: cmsPageId,
    })
  }

  static apiConfig = {
    actions: {
      fetchAll(shopId) {
        return this.get(`/shops/${shopId}/cms_pages`)
      },
    },
  }
}
