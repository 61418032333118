import BaseModel from '@/models/BaseModel'

export default class VatTax extends BaseModel {
  static entity = 'vat_taxes'
  static modelPath = 'vat_taxes'
  static formKey = 'vat_tax'

  static fields() {
    return {
      id: this.uid(),
      name: this.string(''),
      value: this.number(0),
    }
  }
}
