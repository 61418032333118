import Vue from "vue";

const mixin = {
  methods: {
    actionSuccess(msg, duration = 3000) {
      this.$buefy.toast.open({
        message: msg,
        type: "is-success",
        duration,
        queue: false
        // position: 'is-top-right',
      });
    },
    actionFailed(msg) {
      this.$buefy.toast.open({
        message: msg,
        type: "is-danger",
        duration: 5000,
        queue: false
      });
    },
    saveFailed(err) {
      const errorCode = err.response?.status ?? "";
      this.$buefy.toast.open({
        message: `Błąd ${errorCode} podczas zapisu`,
        type: "is-danger",
        duration: 5000,
        queue: false
      });
    }
  }
};

if (!Vue.notifications_mixin) {
  Vue.notifications_mixin = true;
  Vue.mixin(mixin);
}
