import Vue from 'vue'

import { ConfigProgrammatic } from 'buefy'
import ConfirmDialog from '@/mixins/confirmDiscard'

// works globally
ConfigProgrammatic.setOptions({
  defaultProgrammaticPromise: true,
})

const mixin = {
  methods: {
    async confirmDiscard() {
      return await ConfirmDialog({
        title: 'Niezapisane zmiany w formularzu',
        message:
          'Klikając <b>OK</b> utracisz niezapisane dane.<br /><b>Kontynuować?</b>',
        cancelText: 'wróć',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => true,
        onCancel: () => false,
      })
    },
  },
}

if (!Vue.confirm_discard_mixin) {
  Vue.confirm_discard_mixin = true
  Vue.mixin(mixin)
}
