import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1d2dc9a6 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _50f5047d = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _1dd938cc = () => interopDefault(import('../pages/clients.vue' /* webpackChunkName: "pages/clients" */))
const _ce6ed356 = () => interopDefault(import('../pages/cms-pages/index.vue' /* webpackChunkName: "pages/cms-pages/index" */))
const _1055922a = () => interopDefault(import('../pages/customers/index.vue' /* webpackChunkName: "pages/customers/index" */))
const _469c43ae = () => interopDefault(import('../pages/desktop.vue' /* webpackChunkName: "pages/desktop" */))
const _2d6670e7 = () => interopDefault(import('../pages/discounts/index.vue' /* webpackChunkName: "pages/discounts/index" */))
const _8a226d4c = () => interopDefault(import('../pages/employees/index.vue' /* webpackChunkName: "pages/employees/index" */))
const _7c0206de = () => interopDefault(import('../pages/features/index.vue' /* webpackChunkName: "pages/features/index" */))
const _2068b528 = () => interopDefault(import('../pages/file_shares/index.vue' /* webpackChunkName: "pages/file_shares/index" */))
const _fe70e2d0 = () => interopDefault(import('../pages/gifts/index.vue' /* webpackChunkName: "pages/gifts/index" */))
const _5e0baafb = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _9f0a8630 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _57a504dc = () => interopDefault(import('../pages/new-products/index.vue' /* webpackChunkName: "pages/new-products/index" */))
const _14868d06 = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _7b118748 = () => interopDefault(import('../pages/pdf_catalogs/index.vue' /* webpackChunkName: "pages/pdf_catalogs/index" */))
const _baa0e0b2 = () => interopDefault(import('../pages/product-bestsellers/index.vue' /* webpackChunkName: "pages/product-bestsellers/index" */))
const _7453ef45 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _daf78a00 = () => interopDefault(import('../pages/shop-configuration/index.vue' /* webpackChunkName: "pages/shop-configuration/index" */))
const _58a3d9bb = () => interopDefault(import('../pages/brands/new.vue' /* webpackChunkName: "pages/brands/new" */))
const _b48c51ea = () => interopDefault(import('../pages/categories/new.vue' /* webpackChunkName: "pages/categories/new" */))
const _b710023a = () => interopDefault(import('../pages/cms-pages/new.vue' /* webpackChunkName: "pages/cms-pages/new" */))
const _218f55f5 = () => interopDefault(import('../pages/discounts/new.vue' /* webpackChunkName: "pages/discounts/new" */))
const _6e292f28 = () => interopDefault(import('../pages/employees/new.vue' /* webpackChunkName: "pages/employees/new" */))
const _3f35fdac = () => interopDefault(import('../pages/features/new.vue' /* webpackChunkName: "pages/features/new" */))
const _0a51e7ba = () => interopDefault(import('../pages/file_shares/new.vue' /* webpackChunkName: "pages/file_shares/new" */))
const _694e2be6 = () => interopDefault(import('../pages/gifts/new.vue' /* webpackChunkName: "pages/gifts/new" */))
const _561e9096 = () => interopDefault(import('../pages/pdf_catalogs/new.vue' /* webpackChunkName: "pages/pdf_catalogs/new" */))
const _6aee9a42 = () => interopDefault(import('../pages/products/import.vue' /* webpackChunkName: "pages/products/import" */))
const _b036585a = () => interopDefault(import('../pages/products/new.vue' /* webpackChunkName: "pages/products/new" */))
const _3aaf9cdc = () => interopDefault(import('../pages/settings/contact-form/index.vue' /* webpackChunkName: "pages/settings/contact-form/index" */))
const _e1e6d596 = () => interopDefault(import('../pages/settings/email-settings/index.vue' /* webpackChunkName: "pages/settings/email-settings/index" */))
const _34d3a240 = () => interopDefault(import('../pages/settings/integrations/index.vue' /* webpackChunkName: "pages/settings/integrations/index" */))
const _3c50d77a = () => interopDefault(import('../pages/settings/newsletter/index.vue' /* webpackChunkName: "pages/settings/newsletter/index" */))
const _6d6a0c72 = () => interopDefault(import('../pages/settings/shops/index.vue' /* webpackChunkName: "pages/settings/shops/index" */))
const _66adca8a = () => interopDefault(import('../pages/settings/social-accounts/index.vue' /* webpackChunkName: "pages/settings/social-accounts/index" */))
const _509726de = () => interopDefault(import('../pages/settings/vat/index.vue' /* webpackChunkName: "pages/settings/vat/index" */))
const _7c95738a = () => interopDefault(import('../pages/settings/shops/ShopForm.vue' /* webpackChunkName: "pages/settings/shops/ShopForm" */))
const _075a64c9 = () => interopDefault(import('../pages/settings/social-accounts/new.vue' /* webpackChunkName: "pages/settings/social-accounts/new" */))
const _d737f038 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6bb860dd = () => interopDefault(import('../pages/settings/email-settings/_id.vue' /* webpackChunkName: "pages/settings/email-settings/_id" */))
const _54a26948 = () => interopDefault(import('../pages/settings/integrations/_id.vue' /* webpackChunkName: "pages/settings/integrations/_id" */))
const _18b6e03a = () => interopDefault(import('../pages/settings/social-accounts/_id.vue' /* webpackChunkName: "pages/settings/social-accounts/_id" */))
const _6e112f49 = () => interopDefault(import('../pages/settings/vat/_vatId.vue' /* webpackChunkName: "pages/settings/vat/_vatId" */))
const _44ee04d5 = () => interopDefault(import('../pages/brands/_id.vue' /* webpackChunkName: "pages/brands/_id" */))
const _dbf7fbb6 = () => interopDefault(import('../pages/categories/_id.vue' /* webpackChunkName: "pages/categories/_id" */))
const _de7bac06 = () => interopDefault(import('../pages/cms-pages/_id.vue' /* webpackChunkName: "pages/cms-pages/_id" */))
const _24d3d1dc = () => interopDefault(import('../pages/customers/_id.vue' /* webpackChunkName: "pages/customers/_id" */))
const _0dd9810f = () => interopDefault(import('../pages/discounts/_id.vue' /* webpackChunkName: "pages/discounts/_id" */))
const _5a735a42 = () => interopDefault(import('../pages/employees/_id.vue' /* webpackChunkName: "pages/employees/_id" */))
const _fabefa6e = () => interopDefault(import('../pages/features/_id/index.vue' /* webpackChunkName: "pages/features/_id/index" */))
const _12c7da58 = () => interopDefault(import('../pages/file_shares/_id.vue' /* webpackChunkName: "pages/file_shares/_id" */))
const _55985700 = () => interopDefault(import('../pages/gifts/_id.vue' /* webpackChunkName: "pages/gifts/_id" */))
const _177fce24 = () => interopDefault(import('../pages/orders/_id.vue' /* webpackChunkName: "pages/orders/_id" */))
const _4268bbb0 = () => interopDefault(import('../pages/pdf_catalogs/_id.vue' /* webpackChunkName: "pages/pdf_catalogs/_id" */))
const _d7a20226 = () => interopDefault(import('../pages/products/_id.vue' /* webpackChunkName: "pages/products/_id" */))
const _7375e56b = () => interopDefault(import('../pages/shop-configuration/_section/index.vue' /* webpackChunkName: "pages/shop-configuration/_section/index" */))
const _55d1cf30 = () => interopDefault(import('../pages/features/_id/list-values.vue' /* webpackChunkName: "pages/features/_id/list-values" */))
const _ae89efd6 = () => interopDefault(import('../pages/shop-configuration/_section/_component_id.vue' /* webpackChunkName: "pages/shop-configuration/_section/_component_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/brands",
    component: _1d2dc9a6,
    name: "brands"
  }, {
    path: "/categories",
    component: _50f5047d,
    name: "categories"
  }, {
    path: "/clients",
    component: _1dd938cc,
    name: "clients"
  }, {
    path: "/cms-pages",
    component: _ce6ed356,
    name: "cms-pages"
  }, {
    path: "/customers",
    component: _1055922a,
    name: "customers"
  }, {
    path: "/desktop",
    component: _469c43ae,
    name: "desktop"
  }, {
    path: "/discounts",
    component: _2d6670e7,
    name: "discounts"
  }, {
    path: "/employees",
    component: _8a226d4c,
    name: "employees"
  }, {
    path: "/features",
    component: _7c0206de,
    name: "features"
  }, {
    path: "/file_shares",
    component: _2068b528,
    name: "file_shares"
  }, {
    path: "/gifts",
    component: _fe70e2d0,
    name: "gifts"
  }, {
    path: "/login",
    component: _5e0baafb,
    name: "login"
  }, {
    path: "/logout",
    component: _9f0a8630,
    name: "logout"
  }, {
    path: "/new-products",
    component: _57a504dc,
    name: "new-products"
  }, {
    path: "/orders",
    component: _14868d06,
    name: "orders"
  }, {
    path: "/pdf_catalogs",
    component: _7b118748,
    name: "pdf_catalogs"
  }, {
    path: "/product-bestsellers",
    component: _baa0e0b2,
    name: "product-bestsellers"
  }, {
    path: "/products",
    component: _7453ef45,
    name: "products"
  }, {
    path: "/shop-configuration",
    component: _daf78a00,
    name: "shop-configuration"
  }, {
    path: "/brands/new",
    component: _58a3d9bb,
    name: "brands-new"
  }, {
    path: "/categories/new",
    component: _b48c51ea,
    name: "categories-new"
  }, {
    path: "/cms-pages/new",
    component: _b710023a,
    name: "cms-pages-new"
  }, {
    path: "/discounts/new",
    component: _218f55f5,
    name: "discounts-new"
  }, {
    path: "/employees/new",
    component: _6e292f28,
    name: "employees-new"
  }, {
    path: "/features/new",
    component: _3f35fdac,
    name: "features-new"
  }, {
    path: "/file_shares/new",
    component: _0a51e7ba,
    name: "file_shares-new"
  }, {
    path: "/gifts/new",
    component: _694e2be6,
    name: "gifts-new"
  }, {
    path: "/pdf_catalogs/new",
    component: _561e9096,
    name: "pdf_catalogs-new"
  }, {
    path: "/products/import",
    component: _6aee9a42,
    name: "products-import"
  }, {
    path: "/products/new",
    component: _b036585a,
    name: "products-new"
  }, {
    path: "/settings/contact-form",
    component: _3aaf9cdc,
    name: "settings-contact-form"
  }, {
    path: "/settings/email-settings",
    component: _e1e6d596,
    name: "settings-email-settings"
  }, {
    path: "/settings/integrations",
    component: _34d3a240,
    name: "settings-integrations"
  }, {
    path: "/settings/newsletter",
    component: _3c50d77a,
    name: "settings-newsletter"
  }, {
    path: "/settings/shops",
    component: _6d6a0c72,
    name: "settings-shops"
  }, {
    path: "/settings/social-accounts",
    component: _66adca8a,
    name: "settings-social-accounts"
  }, {
    path: "/settings/vat",
    component: _509726de,
    name: "settings-vat"
  }, {
    path: "/settings/shops/ShopForm",
    component: _7c95738a,
    name: "settings-shops-ShopForm"
  }, {
    path: "/settings/social-accounts/new",
    component: _075a64c9,
    name: "settings-social-accounts-new"
  }, {
    path: "/",
    component: _d737f038,
    name: "index"
  }, {
    path: "/settings/email-settings/:id?",
    component: _6bb860dd,
    name: "settings-email-settings-id"
  }, {
    path: "/settings/integrations/:id",
    component: _54a26948,
    name: "settings-integrations-id"
  }, {
    path: "/settings/social-accounts/:id?",
    component: _18b6e03a,
    name: "settings-social-accounts-id"
  }, {
    path: "/settings/vat/:vatId",
    component: _6e112f49,
    name: "settings-vat-vatId"
  }, {
    path: "/brands/:id",
    component: _44ee04d5,
    name: "brands-id"
  }, {
    path: "/categories/:id",
    component: _dbf7fbb6,
    name: "categories-id"
  }, {
    path: "/cms-pages/:id?",
    component: _de7bac06,
    name: "cms-pages-id"
  }, {
    path: "/customers/:id",
    component: _24d3d1dc,
    name: "customers-id"
  }, {
    path: "/discounts/:id",
    component: _0dd9810f,
    name: "discounts-id"
  }, {
    path: "/employees/:id",
    component: _5a735a42,
    name: "employees-id"
  }, {
    path: "/features/:id",
    component: _fabefa6e,
    name: "features-id"
  }, {
    path: "/file_shares/:id",
    component: _12c7da58,
    name: "file_shares-id"
  }, {
    path: "/gifts/:id",
    component: _55985700,
    name: "gifts-id"
  }, {
    path: "/orders/:id",
    component: _177fce24,
    name: "orders-id"
  }, {
    path: "/pdf_catalogs/:id",
    component: _4268bbb0,
    name: "pdf_catalogs-id"
  }, {
    path: "/products/:id",
    component: _d7a20226,
    name: "products-id"
  }, {
    path: "/shop-configuration/:section",
    component: _7375e56b,
    name: "shop-configuration-section"
  }, {
    path: "/features/:id/list-values",
    component: _55d1cf30,
    name: "features-id-list-values"
  }, {
    path: "/shop-configuration/:section?/:component_id",
    component: _ae89efd6,
    name: "shop-configuration-section-component_id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
