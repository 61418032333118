import { Model } from "@vuex-orm/core";
import { Feature } from "./Feature";

export default class FeatureListValue extends Model {
  static entity = "feature_list_values";

  static fields() {
    return {
      id: this.uid(),
      feature_id: this.number(0),
      position: this.number(1),
      value: this.string(""),
      comment: this.string(""),
      feature: this.belongsTo(Feature, "feature_id")
    };
  }

  static create(shopId, featureId, form) {
    const data = {
      feature_list_value: form
    };
    return this.api().post(
      `shops/${shopId}/feature_lists/${featureId}/feature_list_values`,
      data
    );
  }

  static remove(shopId, featureId, featureListValueId) {
    return this.api().delete(
      `/shops/${shopId}/feature_lists/${featureId}/feature_list_values/${featureListValueId}`,
      {
        delete: featureListValueId
      }
    );
  }

  static getAllByFeatureId(shopId, featureId) {
    return this.api().get(
      `shops/${shopId}/feature_lists/${featureId}/feature_list_values`
    );
  }

  static setById(activeShopId, featureId, featureListValueId, form) {
    const data = {
      feature_list_value: form
    };
    return this.api().put(
      `shops/${activeShopId}/feature_lists/${featureId}/feature_list_values/${featureListValueId}`,
      data
    );
  }

  static fetchAll(shopId) {
    return this.api().get(
      `shops/${shopId}/feature_list_values`
    );
  }

  static countProducts(shopId, featureId, featureListValueId) {
    const config = {
      save: false
    };
    return this.api().get(`shops/${shopId}/feature_lists/${featureId}/feature_list_values/${featureListValueId}/products_count`, config).then(({ response }) => {
      return response?.data?.products_count || 0;
    });
  }


  static setPosition(activeShopId, featureId, featureListValueId, position) {
    const data = {
      feature_list_value: {
        position
      }
    };
    return this.api().put(
      `shops/${activeShopId}/feature_lists/${featureId}/feature_list_values/${featureListValueId}/set_position`,
      data
    );
  }


  static apiConfig = {
    actions: {
      fetchAll(shopId, featureId) {
        return this.get(
          `/shops/${shopId}/feature_lists/${featureId}/feature_list_values`
        );
      }
    }
  };
}
