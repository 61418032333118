import BaseModel from "@/models/BaseModel";
import Customer from "@/models/Customer";

export default class Discount extends BaseModel {
  static entity = "discounts";
  static modelPath = "discounts";
  static formKey = "discount";

  static fields() {
    return {
      id: this.uid(),
      discount_type: this.string(""),
      shop_id: this.number(null),
      currency_id: this.number(null),
      customer_id: this.number(null).nullable(),
      discount_code: this.string(""),
      value: this.number(0),
      min_order_value: this.number(0),
      is_active: this.boolean(true),
      start_date: this.attr([]).nullable(),
      end_date: this.attr([]).nullable(),
      single_usage: this.boolean(false),
      customer: this.belongsTo(Customer, "customer_id")
    };
  }


  static apiConfig = {
    actions: {
      fetchAll(shopId) {
        return this.get(`/shops/${shopId}/gifts`);
      }
    }
  };


}
