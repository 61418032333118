import BaseModel from './BaseModel'
import Product from '@/models/Product'
import Customer from '@/models/Customer'

export default class ProductNotification extends BaseModel {
  static entity = 'product_notifications'
  static modelPath = 'product_notifications'
  static formKey = 'product_notification'

  static fields() {
    return {
      id: this.uid(),
      customer_id: this.attr(null),
      email: this.string(''),
      confirmed: this.boolean(false),
      cyclic: this.boolean(false),
      product_id: this.attr(null),
      product_notification_kind_id: this.attr(null),
      shop_id: this.attr(null),
      uuid: this.uid(),
      product: this.belongsTo(Product, 'product_id'),
      customer: this.belongsTo(Customer, 'customer_id'),
    }
  }

  static resendConfirmation(shopId, id) {
    return this.api().put(
      `/shops/${shopId}/${this.modelPath}/${id}/resend_confirmation`
    )
  }

  get getCustomerFullName() {
    if (this.customer?.name || this.customer?.last_name)
      return `${this.customer?.name ?? ''} ${this.customer?.last_name ?? ''}`
    else return ''
  }
}
