export default (context) => {
  const {
    app,
    store,
    // route,
    // params,
    // query,
    // env,
    // isDev,
    // isHMR,
    // redirect,
    // error,
    // $config,
    $axios,
  } = context

  $axios.onRequest((config) => {
    const method = config.method.toUpperCase()
    const url = config.url
    // handle refresh token - put it in header as
    if (
      (url === '/session/refresh' && method === 'POST') ||
      (url === '/session' && method === 'DELETE')
    ) {
      const refreshToken = app.$auth.strategy.refreshToken.get()
      config.headers = {
        ...config.headers,
        'X-Refresh-Token': refreshToken,
      }
    }

    if (method !== 'OPTIONS' && method !== 'GET') {
      const csrfToken = store.state.shopAuth.csrfToken
      config.headers = {
        ...config.headers,
        'X-CSRF-TOKEN': csrfToken,
      }
    }
    return config
  })
  $axios.onError(() => {
    // console.log('on error vuexorm plugin')
    // console.log(error)
    // const code = parseInt(error.response && error.response.status)
    // console.log(code)
    // if (code === 401) {
    //     redirect('/login')
    // }
  })
}
