import BaseModel from './BaseModel'
import ConfigurationScope from './ConfigurationScope'
import { Component } from '@/models/ComponentHierarchy.js'

export default class ShopConfiguration extends BaseModel {
  static entity = 'shop_configurations'
  static modelPath = 'shop_configurations'
  static formKey = 'shop_configuration'

  static fields() {
    return {
      id: this.uid(),
      shop_id: this.number(''),
      configuration_scope_id: this.number(''),
      component_id: this.number(''),
      position: this.number(''),
      enabled: this.boolean(false),
      configuration: this.attr(null),
      entitle_ids: this.attr(null),
      component: this.belongsTo(Component, 'component_id'),
      configuration_scope: this.belongsTo(
        ConfigurationScope,
        'configuration_scope_id'
      ),
    }
  }

  static fetchAllConfiguration(scope) {
    return this.api().get(`shop/configuration/${scope}`)
  }

  static setPosition(shopId, id, position) {
    const data = {
      shop_configuration: {
        position,
      },
    }
    return this.api().put(
      `/shops/${shopId}/shop_configurations/${id}/set_position`,
      data
    )
  }
}
