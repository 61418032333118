import BaseModel from '@/models/BaseModel'

export default class SellDocumentType extends BaseModel {
  static entity = 'sell_document_types'
  static modelPath = 'sell_document_types'
  static formKey = 'sell_document_type'

  static fields() {
    return {
      id: this.uid(),
      name: this.string(''),
      symbol: this.string(''),
      include_tax: this.boolean(''),
      require_company_data: this.boolean(''),
    }
  }
}
