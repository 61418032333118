import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import database from '@/database'

VuexORM.use(VuexORMAxios, {
  baseURL: process.env.API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})
export const plugins = [VuexORM.install(database)]
export const actions = {
  async nuxtServerInit({ dispatch }) {
    if (this.$auth.loggedIn) {
      // console.log('shop/initState')
      await dispatch('shop/initState')
    } else {
      // console.log('not logged in')
    }
  },
}
