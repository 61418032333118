import BaseModel from '@/models/BaseModel'

export default class DeliveryPickUpPointProvider extends BaseModel {
  static entity = 'delivery_pick_up_point_providers'
  static modelPath = 'delivery_pick_up_point_providers'
  static formKey = 'delivery_pick_up_point_provider'

  static fields() {
    return {
      id: this.uid(),
      name: this.string(''),
      symbol: this.string(''),
    }
  }
}
