import { DialogProgrammatic as Dialog } from 'buefy'

export default async function () {
  const { result } = await Dialog.confirm({
    title: 'Niezapisane zmiany w formularzu',
    message:
      'Klikając <b>OK</b> utracisz niezapisane dane.<br /><b>Kontynuować?</b>',
    cancelText: 'wróć',
    type: 'is-danger',
    hasIcon: true,
    onConfirm: () => true,
    onCancel: () => false,
  })
  return result
}
