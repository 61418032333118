import { Model } from '@vuex-orm/core'
// import Product from './Product'
// import Category from './Category'
export default class ProductCategory extends Model {
  static entity = 'product_categories'

  static primaryKey = ['product_id', 'category_id']

  static fields() {
    return {
      id: this.uid(),
      product_id: this.number(0),
      category_id: this.number(0),
    }
  }

  static fetchForProductId(shopId, productId) {
    return this.api().get(
      `/shops/${shopId}/products/${productId}/product_categories`
    )
  }

  static setForProductId(shopId, productId, categories) {
    // remove unused vuex client side
    const toRemoveIds = this.query()
      .where('product_id', productId)
      .get()
      .filter((pc) => !categories.includes(pc.category_id))
      .map((pc) => pc.id)
    this.delete((pc) => {
      return toRemoveIds.includes(pc.id)
    })
    const postData = {
      product_category: {
        categories,
      },
    }
    return this.api().post(
      `/shops/${shopId}/products/${productId}/product_categories`,
      postData
    )
  }
}
