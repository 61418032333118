
export default {
  name: 'ErrorLayout',
  layout: 'blog',
  props: {
    error: {
      type: String,
      required: false,
      default: '',
    },
  }, // you can set a custom layout for the error page
}
