import BaseModel from "@/models/BaseModel";

export default class FileShare extends BaseModel {
  static entity = "file_shares";
  static modelPath = "file_shares";
  static formKey = "file_share";

  static fields() {
    return {
      id: this.uid(),
      name: this.string(""),
      file_path: this.string("")
    };
  }

  get getFileUrl() {
    if (!this.file_path) {
      return false;
    }
    return this.baseImageUrl + this.file_path;
  }

  get fileExists() {
    return !!this.file_path;
  }

  get baseImageUrl() {
    return this.$store().getters["shop/imageBaseUrl"];
  }
}
