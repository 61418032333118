import BaseModel from "@/models/BaseModel";
import Product from "@/models/Product";
import VatTax from "@/models/VatTax";
import ProductVariant from "@/models/ProductVariant";

export default class OrderItem extends BaseModel {
  static entity = "order_items";
  static modelPath = "order_items";
  static formKey = "order_item";

  static fields() {
    return {
      id: this.uid(),
      order_id: this.number(""),
      product_id: this.number(""),
      product_variant_id: this.number(""),
      price_net: this.number(""),
      vat_tax_id: this.number(""),
      items: this.number(""),
      product: this.belongsTo(Product, "product_id"),
      product_variant: this.belongsTo(ProductVariant, "product_variant_id"),
      vat_tax: this.belongsTo(VatTax, "vat_tax_id")
    };
  }

  get vatTaxPercent() {
    return this.vat_tax.value;
  }

  get priceGross() {
    return (this.price_net * (100 + this.vat_tax.value)) / 100;
  }

  get itemsValueGross() {
    return this.items * this.priceGross;
  }

  get itemsValueNet() {
    return this.items * this.price_net;
  }
}
