import { Model } from '@vuex-orm/core'
import Currency from './Currency'
import Category from './Category'
export default class Shop extends Model {
  static entity = 'shops'

  static fields() {
    return {
      id: this.uid(),
      name: this.string(''),
      domain: this.string(''),
      https_enabled: this.boolean(false),
      root_category_id: this.number(),
      locale: this.string(''),
      currency_id: this.number(0),
      currency: this.belongsTo(Currency, 'currency_id'),
      category: this.belongsTo(Category, 'root_category_id'),
    }
  }

  static apiConfig = {
    actions: {
      fetchAll() {
        return this.get('/shops')
      },
    },
  }
}
