import { Model } from "@vuex-orm/core";
import ProductFeature from "./ProductFeature";

export default class ProductFeatureValueString extends Model {
  static entity = "product_feature_value_strings";

  static fields() {
    return {
      id: this.uid(),
      value: this.string(""),
      product_feature: this.morphOne(
        ProductFeature,
        "feature_settable_id",
        "feature_settable_type"
      )
    };
  }

  static setById(shopId, productId, productFeatureId, value) {
    const data = {
      product_feature_value_string: {
        value
      }
    };
    return this.api().put(
      `/shops/${shopId}/products/${productId}/product_features/${productFeatureId}/set_product_feature_value`,
      data
    );
  }
}
