export const Card = () => import('../../components/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const CustomModal = () => import('../../components/CustomModal.js' /* webpackChunkName: "components/custom-modal" */).then(c => wrapFunctional(c.default || c))
export const Editor = () => import('../../components/Editor.vue' /* webpackChunkName: "components/editor" */).then(c => wrapFunctional(c.default || c))
export const GlobalDirtyNotificator = () => import('../../components/GlobalDirtyNotificator.vue' /* webpackChunkName: "components/global-dirty-notificator" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const BrandsBrandForm = () => import('../../components/Brands/BrandForm.vue' /* webpackChunkName: "components/brands-brand-form" */).then(c => wrapFunctional(c.default || c))
export const CategoriesCategoryForm = () => import('../../components/Categories/CategoryForm.vue' /* webpackChunkName: "components/categories-category-form" */).then(c => wrapFunctional(c.default || c))
export const CmsPageForm = () => import('../../components/Cms/CmsPageForm.vue' /* webpackChunkName: "components/cms-page-form" */).then(c => wrapFunctional(c.default || c))
export const CustomersCustomerAddressForm = () => import('../../components/Customers/CustomerAddressForm.vue' /* webpackChunkName: "components/customers-customer-address-form" */).then(c => wrapFunctional(c.default || c))
export const CustomersCustomerAddressModal = () => import('../../components/Customers/CustomerAddressModal.vue' /* webpackChunkName: "components/customers-customer-address-modal" */).then(c => wrapFunctional(c.default || c))
export const CustomersCustomerForm = () => import('../../components/Customers/CustomerForm.vue' /* webpackChunkName: "components/customers-customer-form" */).then(c => wrapFunctional(c.default || c))
export const DiscountForm = () => import('../../components/Discount/DiscountForm.vue' /* webpackChunkName: "components/discount-form" */).then(c => wrapFunctional(c.default || c))
export const EmailSettingForm = () => import('../../components/EmailSetting/EmailSettingForm.vue' /* webpackChunkName: "components/email-setting-form" */).then(c => wrapFunctional(c.default || c))
export const EmailSettingSendTestEmailForm = () => import('../../components/EmailSetting/SendTestEmailForm.vue' /* webpackChunkName: "components/email-setting-send-test-email-form" */).then(c => wrapFunctional(c.default || c))
export const EmployeeForm = () => import('../../components/Employee/EmployeeForm.vue' /* webpackChunkName: "components/employee-form" */).then(c => wrapFunctional(c.default || c))
export const FeaturesFeatureForm = () => import('../../components/Features/FeatureForm.vue' /* webpackChunkName: "components/features-feature-form" */).then(c => wrapFunctional(c.default || c))
export const FeaturesFeatureListValueForm = () => import('../../components/Features/FeatureListValueForm.vue' /* webpackChunkName: "components/features-feature-list-value-form" */).then(c => wrapFunctional(c.default || c))
export const FeaturesFeatureListValueModal = () => import('../../components/Features/FeatureListValueModal.vue' /* webpackChunkName: "components/features-feature-list-value-modal" */).then(c => wrapFunctional(c.default || c))
export const FileShareForm = () => import('../../components/FileShares/FileShareForm.vue' /* webpackChunkName: "components/file-share-form" */).then(c => wrapFunctional(c.default || c))
export const GenericEye = () => import('../../components/Generic/Eye.vue' /* webpackChunkName: "components/generic-eye" */).then(c => wrapFunctional(c.default || c))
export const GiftsGiftForm = () => import('../../components/Gifts/GiftForm.vue' /* webpackChunkName: "components/gifts-gift-form" */).then(c => wrapFunctional(c.default || c))
export const LocalBusinessDayEntry = () => import('../../components/LocalBusiness/LocalBusinessDayEntry.vue' /* webpackChunkName: "components/local-business-day-entry" */).then(c => wrapFunctional(c.default || c))
export const LocalBusinessEditModal = () => import('../../components/LocalBusiness/LocalBusinessEditModal.vue' /* webpackChunkName: "components/local-business-edit-modal" */).then(c => wrapFunctional(c.default || c))
export const LocalBusinessForm = () => import('../../components/LocalBusiness/LocalBusinessForm.vue' /* webpackChunkName: "components/local-business-form" */).then(c => wrapFunctional(c.default || c))
export const LocalBusinessIndex = () => import('../../components/LocalBusiness/LocalBusinessIndex.vue' /* webpackChunkName: "components/local-business-index" */).then(c => wrapFunctional(c.default || c))
export const LocalBusinessOpeningHours = () => import('../../components/LocalBusiness/LocalBusinessOpeningHours.vue' /* webpackChunkName: "components/local-business-opening-hours" */).then(c => wrapFunctional(c.default || c))
export const NavigationBreadcrumbs = () => import('../../components/Navigation/Breadcrumbs.vue' /* webpackChunkName: "components/navigation-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const NotificationsNotificationEditModal = () => import('../../components/Notifications/NotificationEditModal.vue' /* webpackChunkName: "components/notifications-notification-edit-modal" */).then(c => wrapFunctional(c.default || c))
export const NotificationsNotificationForm = () => import('../../components/Notifications/NotificationForm.vue' /* webpackChunkName: "components/notifications-notification-form" */).then(c => wrapFunctional(c.default || c))
export const NotificationsFilterInput = () => import('../../components/Notifications/NotificationsFilterInput.vue' /* webpackChunkName: "components/notifications-filter-input" */).then(c => wrapFunctional(c.default || c))
export const NotificationsIndex = () => import('../../components/Notifications/NotificationsIndex.vue' /* webpackChunkName: "components/notifications-index" */).then(c => wrapFunctional(c.default || c))
export const OrdersCustomerAddressSummary = () => import('../../components/Orders/CustomerAddressSummary.vue' /* webpackChunkName: "components/orders-customer-address-summary" */).then(c => wrapFunctional(c.default || c))
export const OrdersOrderForm = () => import('../../components/Orders/OrderForm.vue' /* webpackChunkName: "components/orders-order-form" */).then(c => wrapFunctional(c.default || c))
export const PdfCatalogForm = () => import('../../components/PdfCatalogs/PdfCatalogForm.vue' /* webpackChunkName: "components/pdf-catalog-form" */).then(c => wrapFunctional(c.default || c))
export const ProductAttachmentFile = () => import('../../components/ProductAttachment/ProductAttachmentFile.vue' /* webpackChunkName: "components/product-attachment-file" */).then(c => wrapFunctional(c.default || c))
export const ProductFeatureListValue = () => import('../../components/ProductFeatures/ProductFeatureListValue.vue' /* webpackChunkName: "components/product-feature-list-value" */).then(c => wrapFunctional(c.default || c))
export const ProductFeatureNumberValue = () => import('../../components/ProductFeatures/ProductFeatureNumberValue.vue' /* webpackChunkName: "components/product-feature-number-value" */).then(c => wrapFunctional(c.default || c))
export const ProductFeatureStringValue = () => import('../../components/ProductFeatures/ProductFeatureStringValue.vue' /* webpackChunkName: "components/product-feature-string-value" */).then(c => wrapFunctional(c.default || c))
export const ProductFeatureValue = () => import('../../components/ProductFeatures/ProductFeatureValue.vue' /* webpackChunkName: "components/product-feature-value" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductForm = () => import('../../components/Products/ProductForm.vue' /* webpackChunkName: "components/products-product-form" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductImportForm = () => import('../../components/Products/ProductImportForm.vue' /* webpackChunkName: "components/products-product-import-form" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductSaveButton = () => import('../../components/Products/ProductSaveButton.vue' /* webpackChunkName: "components/products-product-save-button" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductTabAttachments = () => import('../../components/Products/ProductTabAttachments.vue' /* webpackChunkName: "components/products-product-tab-attachments" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductTabCategories = () => import('../../components/Products/ProductTabCategories.vue' /* webpackChunkName: "components/products-product-tab-categories" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductTabFeatures = () => import('../../components/Products/ProductTabFeatures.vue' /* webpackChunkName: "components/products-product-tab-features" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductTabGeneral = () => import('../../components/Products/ProductTabGeneral.vue' /* webpackChunkName: "components/products-product-tab-general" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductTabPictures = () => import('../../components/Products/ProductTabPictures.vue' /* webpackChunkName: "components/products-product-tab-pictures" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductTabPrices = () => import('../../components/Products/ProductTabPrices.vue' /* webpackChunkName: "components/products-product-tab-prices" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductTabRelatedProducts = () => import('../../components/Products/ProductTabRelatedProducts.vue' /* webpackChunkName: "components/products-product-tab-related-products" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductTabSeo = () => import('../../components/Products/ProductTabSeo.vue' /* webpackChunkName: "components/products-product-tab-seo" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductVariantPrices = () => import('../../components/Products/ProductVariantPrices.vue' /* webpackChunkName: "components/products-product-variant-prices" */).then(c => wrapFunctional(c.default || c))
export const RelatedProductItem = () => import('../../components/RelatedProduct/RelatedProductItem.vue' /* webpackChunkName: "components/related-product-item" */).then(c => wrapFunctional(c.default || c))
export const SectionAddComponent = () => import('../../components/Section/AddComponent.vue' /* webpackChunkName: "components/section-add-component" */).then(c => wrapFunctional(c.default || c))
export const ShopConfigurationComponentNotFound = () => import('../../components/ShopConfiguration/ComponentNotFound.vue' /* webpackChunkName: "components/shop-configuration-component-not-found" */).then(c => wrapFunctional(c.default || c))
export const ShopConfigurationDynamicPageConfiguration = () => import('../../components/ShopConfiguration/DynamicPageConfiguration.vue' /* webpackChunkName: "components/shop-configuration-dynamic-page-configuration" */).then(c => wrapFunctional(c.default || c))
export const ShopConfigurationHome = () => import('../../components/ShopConfiguration/Home.vue' /* webpackChunkName: "components/shop-configuration-home" */).then(c => wrapFunctional(c.default || c))
export const ShopConfigurationSidebarTree = () => import('../../components/ShopConfiguration/SidebarTree.vue' /* webpackChunkName: "components/shop-configuration-sidebar-tree" */).then(c => wrapFunctional(c.default || c))
export const SlidersSliderEditModal = () => import('../../components/Sliders/SliderEditModal.vue' /* webpackChunkName: "components/sliders-slider-edit-modal" */).then(c => wrapFunctional(c.default || c))
export const SlidersSliderForm = () => import('../../components/Sliders/SliderForm.vue' /* webpackChunkName: "components/sliders-slider-form" */).then(c => wrapFunctional(c.default || c))
export const SlidersSliderIndex = () => import('../../components/Sliders/SliderIndex.vue' /* webpackChunkName: "components/sliders-slider-index" */).then(c => wrapFunctional(c.default || c))
export const SocialMediaSimpleConfigurationForm = () => import('../../components/SocialMediaSimpleConfiguration/SocialMediaSimpleConfigurationForm.vue' /* webpackChunkName: "components/social-media-simple-configuration-form" */).then(c => wrapFunctional(c.default || c))
export const UsersLoginForm = () => import('../../components/Users/LoginForm.vue' /* webpackChunkName: "components/users-login-form" */).then(c => wrapFunctional(c.default || c))
export const AuthLogInForm = () => import('../../components/auth/LogInForm.vue' /* webpackChunkName: "components/auth-log-in-form" */).then(c => wrapFunctional(c.default || c))
export const InputsBCheckboxesWithValidation = () => import('../../components/inputs/BCheckboxesWithValidation.vue' /* webpackChunkName: "components/inputs-b-checkboxes-with-validation" */).then(c => wrapFunctional(c.default || c))
export const InputsBDecimalWithValidation = () => import('../../components/inputs/BDecimalWithValidation.vue' /* webpackChunkName: "components/inputs-b-decimal-with-validation" */).then(c => wrapFunctional(c.default || c))
export const InputsBEditorWithValidation = () => import('../../components/inputs/BEditorWithValidation.vue' /* webpackChunkName: "components/inputs-b-editor-with-validation" */).then(c => wrapFunctional(c.default || c))
export const InputsBInputDateWithValidation = () => import('../../components/inputs/BInputDateWithValidation.vue' /* webpackChunkName: "components/inputs-b-input-date-with-validation" */).then(c => wrapFunctional(c.default || c))
export const InputsBInputWithValidation = () => import('../../components/inputs/BInputWithValidation.vue' /* webpackChunkName: "components/inputs-b-input-with-validation" */).then(c => wrapFunctional(c.default || c))
export const InputsBMultipleCheckboxesWithValidation = () => import('../../components/inputs/BMultipleCheckboxesWithValidation.vue' /* webpackChunkName: "components/inputs-b-multiple-checkboxes-with-validation" */).then(c => wrapFunctional(c.default || c))
export const InputsBSelectWithValidation = () => import('../../components/inputs/BSelectWithValidation.vue' /* webpackChunkName: "components/inputs-b-select-with-validation" */).then(c => wrapFunctional(c.default || c))
export const InputsBSingleCheckboxWithValidation = () => import('../../components/inputs/BSingleCheckboxWithValidation.vue' /* webpackChunkName: "components/inputs-b-single-checkbox-with-validation" */).then(c => wrapFunctional(c.default || c))
export const InputsBTextAreaWithValidation = () => import('../../components/inputs/BTextAreaWithValidation.vue' /* webpackChunkName: "components/inputs-b-text-area-with-validation" */).then(c => wrapFunctional(c.default || c))
export const InputsImageSingleViewWithUpload = () => import('../../components/inputs/ImageSingleViewWithUpload.vue' /* webpackChunkName: "components/inputs-image-single-view-with-upload" */).then(c => wrapFunctional(c.default || c))
export const InputsImageUploadWithPreview = () => import('../../components/inputs/ImageUploadWithPreview.vue' /* webpackChunkName: "components/inputs-image-upload-with-preview" */).then(c => wrapFunctional(c.default || c))
export const InputsInputNotSupported = () => import('../../components/inputs/InputNotSupported.vue' /* webpackChunkName: "components/inputs-input-not-supported" */).then(c => wrapFunctional(c.default || c))
export const ProductPictureImage = () => import('../../components/productPictures/ProductPictureImage.vue' /* webpackChunkName: "components/product-picture-image" */).then(c => wrapFunctional(c.default || c))
export const SharedLinkMdi = () => import('../../components/shared/LinkMdi.vue' /* webpackChunkName: "components/shared-link-mdi" */).then(c => wrapFunctional(c.default || c))
export const SharedLoading = () => import('../../components/shared/Loading.vue' /* webpackChunkName: "components/shared-loading" */).then(c => wrapFunctional(c.default || c))
export const SharedMdi = () => import('../../components/shared/Mdi.vue' /* webpackChunkName: "components/shared-mdi" */).then(c => wrapFunctional(c.default || c))
export const SharedNodeTree = () => import('../../components/shared/NodeTree.vue' /* webpackChunkName: "components/shared-node-tree" */).then(c => wrapFunctional(c.default || c))
export const SharedSidebarHelper = () => import('../../components/shared/SidebarHelper.vue' /* webpackChunkName: "components/shared-sidebar-helper" */).then(c => wrapFunctional(c.default || c))
export const SharedSimpleTreeRenderNode = () => import('../../components/shared/SimpleTreeRenderNode.vue' /* webpackChunkName: "components/shared-simple-tree-render-node" */).then(c => wrapFunctional(c.default || c))
export const SharedTree = () => import('../../components/shared/Tree.vue' /* webpackChunkName: "components/shared-tree" */).then(c => wrapFunctional(c.default || c))
export const SharedErrorToastr = () => import('../../components/shared/errorToastr.vue' /* webpackChunkName: "components/shared-error-toastr" */).then(c => wrapFunctional(c.default || c))
export const SharedFooter = () => import('../../components/shared/footer.vue' /* webpackChunkName: "components/shared-footer" */).then(c => wrapFunctional(c.default || c))
export const SharedFormSaveButtonWithNotifications = () => import('../../components/shared/formSaveButtonWithNotifications.vue' /* webpackChunkName: "components/shared-form-save-button-with-notifications" */).then(c => wrapFunctional(c.default || c))
export const SharedHeader = () => import('../../components/shared/header.vue' /* webpackChunkName: "components/shared-header" */).then(c => wrapFunctional(c.default || c))
export const VatTaxEdit = () => import('../../components/vatTaxes/VatTaxEdit.vue' /* webpackChunkName: "components/vat-tax-edit" */).then(c => wrapFunctional(c.default || c))
export const VatTaxForm = () => import('../../components/vatTaxes/VatTaxForm.vue' /* webpackChunkName: "components/vat-tax-form" */).then(c => wrapFunctional(c.default || c))
export const ShopConfigurationComponentsComponentBannerSlider = () => import('../../components/ShopConfiguration/Components/ComponentBannerSlider.vue' /* webpackChunkName: "components/shop-configuration-components-component-banner-slider" */).then(c => wrapFunctional(c.default || c))
export const ShopConfigurationComponentsComponentBrandList = () => import('../../components/ShopConfiguration/Components/ComponentBrandList.vue' /* webpackChunkName: "components/shop-configuration-components-component-brand-list" */).then(c => wrapFunctional(c.default || c))
export const ShopConfigurationComponentsComponentCategoryImport = () => import('../../components/ShopConfiguration/Components/ComponentCategoryImport.vue' /* webpackChunkName: "components/shop-configuration-components-component-category-import" */).then(c => wrapFunctional(c.default || c))
export const ShopConfigurationComponentsComponentGeneric = () => import('../../components/ShopConfiguration/Components/ComponentGeneric.vue' /* webpackChunkName: "components/shop-configuration-components-component-generic" */).then(c => wrapFunctional(c.default || c))
export const ShopConfigurationComponentsComponentImportData = () => import('../../components/ShopConfiguration/Components/ComponentImportData.vue' /* webpackChunkName: "components/shop-configuration-components-component-import-data" */).then(c => wrapFunctional(c.default || c))
export const ShopConfigurationComponentsComponentImportDataFromDb = () => import('../../components/ShopConfiguration/Components/ComponentImportDataFromDb.vue' /* webpackChunkName: "components/shop-configuration-components-component-import-data-from-db" */).then(c => wrapFunctional(c.default || c))
export const ShopConfigurationComponentsComponentLocalBusiness = () => import('../../components/ShopConfiguration/Components/ComponentLocalBusiness.vue' /* webpackChunkName: "components/shop-configuration-components-component-local-business" */).then(c => wrapFunctional(c.default || c))
export const ShopConfigurationComponentsComponentProductList = () => import('../../components/ShopConfiguration/Components/ComponentProductList.vue' /* webpackChunkName: "components/shop-configuration-components-component-product-list" */).then(c => wrapFunctional(c.default || c))
export const ShopConfigurationComponentsComponentProductNotification = () => import('../../components/ShopConfiguration/Components/ComponentProductNotification.vue' /* webpackChunkName: "components/shop-configuration-components-component-product-notification" */).then(c => wrapFunctional(c.default || c))
export const ShopConfigurationComponentsComponentStructuredData = () => import('../../components/ShopConfiguration/Components/ComponentStructuredData.vue' /* webpackChunkName: "components/shop-configuration-components-component-structured-data" */).then(c => wrapFunctional(c.default || c))
export const ShopConfigurationComponentsDummy = () => import('../../components/ShopConfiguration/Components/Dummy.vue' /* webpackChunkName: "components/shop-configuration-components-dummy" */).then(c => wrapFunctional(c.default || c))
export const ShopConfigurationComponentsSelectItemsWithPicture = () => import('../../components/ShopConfiguration/Components/SelectItemsWithPicture.vue' /* webpackChunkName: "components/shop-configuration-components-select-items-with-picture" */).then(c => wrapFunctional(c.default || c))
export const ShopConfigurationSectionsGenericSectionSettings = () => import('../../components/ShopConfiguration/Sections/GenericSectionSettings.vue' /* webpackChunkName: "components/shop-configuration-sections-generic-section-settings" */).then(c => wrapFunctional(c.default || c))
export const ShopConfigurationSectionsHomePageSettings = () => import('../../components/ShopConfiguration/Sections/HomePageSettings.vue' /* webpackChunkName: "components/shop-configuration-sections-home-page-settings" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
