import { Model } from '@vuex-orm/core'
import Category from './Category'
import { Feature } from './Feature'

export default class CategoryFeature extends Model {
  static entity = 'category_features'

  static fields() {
    return {
      id: this.uid(),
      category_id: this.number(null).nullable(),
      feature_id: this.number(null).nullable(),
      position: this.number(''),
      category: this.belongsTo(Category, 'category_id'),
      feature: this.belongsTo(Feature, 'feature_id'),
    }
  }

  static create(shopId, categoryId, featureId) {
    const data = {
      category_feature: {
        feature_id: featureId,
      },
    }
    return this.api().post(
      `/shops/${shopId}/categories/${categoryId}/category_features`,
      data
    )
  }

  static remove(shopId, categoryId, categoryFeatureId) {
    return this.api().delete(
      `/shops/${shopId}/categories/${categoryId}/category_features/${categoryFeatureId}`,
      {
        delete: categoryFeatureId,
      }
    )
  }

  static getByCategoryId(shopId, categoryId) {
    return this.api().get(
      `/shops/${shopId}/categories/${categoryId}/category_features`
    )
  }

  static setById(shopId, categoryId, categoryFeatureId, form) {
    const data = {
      category_feature: form,
    }
    return this.api().put(
      `/shops/${shopId}/categories/${categoryId}/category_features/${categoryFeatureId}`,
      data
    )
  }
}
