import BaseModel from "@/models/BaseModel";

export default class PdfCatalog extends BaseModel {
  static entity = "pdf_catalogs";
  static modelPath = "pdf_catalogs";
  static formKey = "pdf_catalog";

  static fields() {
    return {
      id: this.uid(),
      title: this.string(""),
      position: this.number(0),
      pdf_path: this.string(""),
      cover_path: this.string(""),
      flow_paper_link: this.string("")
    };
  }

  get getCoverUrl() {
    if (!this.cover_path) {
      return false;
    }
    return this.baseImageUrl + this.cover_path;
  }

  get coverExists() {
    return !!this.cover_path;
  }

  get getPdfUrl() {
    if (!this.pdf_path) {
      return false;
    }
    return this.baseImageUrl + this.pdf_path;
  }

  get baseImageUrl() {
    return this.$store().getters["shop/imageBaseUrl"];
  }
}
