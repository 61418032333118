import BaseModel from '@/models/BaseModel'

export default class Country extends BaseModel {
  static entity = 'countries'
  static modelPath = 'countries'
  static formKey = 'country'

  static fields() {
    return {
      id: this.uid(),
      name: this.string(''),
      code: this.string(''),
    }
  }
}
