import BaseModel from './BaseModel'

export default class Newsletter extends BaseModel {
  static entity = 'newsletters'
  static modelPath = 'newsletter'
  static formKey = 'newsletter'

  static fields() {
    return {
      id: this.uid(),
      email: this.string(''),
      confirmed: this.boolean(false),
      customer_id: this.number(),
      created_at: this.attr(),
      updated_at: this.attr(),
    }
  }

  static downloadCSV(shopId) {
    return this.api().get(`shops/${shopId}/newsletter/download_csv`)
  }
}
