import BaseModel from '@/models/BaseModel'

export default class SocialMediaSimpleIntegration extends BaseModel {
  static entity = 'social_media_simple_integrations'
  static modelPath = 'social_media_simple_integrations'
  static formKey = 'social_media_simple_integration'

  static fields() {
    return {
      id: this.uid(),
      name: this.string(''),
      allow_share_content: this.boolean(true),
      base_url: this.string(''),
      symbol: this.string(''),
    }
  }
}
