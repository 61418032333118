export const state = () => ({
  myState: 1,
})

export const getters = {
  getMyState: (state) => {
    return state.myState
  },
}

export const mutations = {
  SET_STATE(state, payload) {
    state.myState = payload.newState
  },
}
export const actions = {
  initMyState({ commit }) {
    commit('SET_STATE', { newState: 'ola' })
  },
}
