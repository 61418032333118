
import { mapActions, mapGetters } from "vuex";
import { SnackbarProgrammatic as Snackbar } from "buefy";
import MyHeader from "~/components/shared/header";
import MyFooter from "~/components/shared/footer";
// import errorToastr from '~/components/shared/errorToastr'
export default {
  name: "App",
  components: {
    MyHeader,
    MyFooter
  },
  async fetch() {
    // if (this.$auth.loggedIn) {
    //   console.log('fetch from default layput')
    //   await this.initState()
    // }
  },
  computed: {
    ...mapGetters({
      productionTest: "shop/productionTest"
    })
  },
  methods: {
    ...mapActions({
      initState: "shop/initState",
      clearStore: "shop/clearStore"
    })
  },
  mounted() {
    if (this.productionTest) {
      Snackbar.open({ message: "Serwer TESTOWY", indefinite: true });
    }

  }
};
