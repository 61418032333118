import SocialMediaSimpleIntegration from './SocialMediaSimpleIntegration'
import BaseModel from '@/models/BaseModel'

export default class SocialMediaSimpleConfiguration extends BaseModel {
  static entity = 'social_media_simple_configurations'
  static modelPath = 'social_media_simple_configurations'
  static formKey = 'social_media_simple_configuration'

  static fields() {
    return {
      id: this.uid(),
      social_media_simple_integration_id: this.attr(null),
      enable_share: this.boolean(true),
      enable_profile_link: this.boolean(true),
      profile_link: this.string(''),
      social_media_simple_integration: this.belongsTo(
        SocialMediaSimpleIntegration,
        'social_media_simple_integration_id'
      ),
    }
  }

  get getEditLink() {
    return `/settings/social-accounts/${this.id}`
  }

  get getLink() {
    return this.profile_link === ''
      ? false
      : this.social_media_simple_integration.base_url + this.profile_link
  }

  get allowShare() {
    return this.social_media_simple_integration.allow_share_content
  }
}
