import BaseModel from '@/models/BaseModel'

export default class OrderStatus extends BaseModel {
  static entity = 'order_statuses'
  static modelPath = 'order_statuses'
  static formKey = 'order_status'

  static fields() {
    return {
      id: this.uid(),
      name: this.string(''),
      symbol: this.string(''),
    }
  }

  static fetchAll() {
    return this.api().get(`${this.modelPath}`)
  }
}
