import BaseModel from './BaseModel'
// import { keysToSnake } from '~/helpers/caseConverter'

export default class IntegrationPrestashopProduct extends BaseModel {
  static entity = 'integration_prestashop_product'
  static modelPath = 'integration_prestashop_products'
  static formKey = 'integration_prestashop_product'

  static fields() {
    return {
      id: this.uid(),
      name: this.string(''),
      prestashop_id: this.string(''),
      imported: this.boolean(false),
    }
  }

  static getProductListQuery(shopId, data) {
    const config = {
      save: false,
    }
    return this.api()
      .get(`shops/${shopId}/integrations/prestashop`, data, config)
      .then((response) => {
        const data = response.response.data
        IntegrationPrestashopProduct.deleteAll()
        IntegrationPrestashopProduct.insert({ data: data.products })
        return parseInt(data.count_total)
      })
  }

  static importProductList(shopId) {
    return this.api().post(
      `shops/${shopId}/integrations/prestashop/import_product_list`
    )
  }

  static importProduct(shopId, id) {
    return this.api().post(
      `shops/${shopId}/integrations/prestashop/${id}/import_product`
    )
  }

  static importMultipleProduct(shopId, ids) {
    const data = {
      integration_prestashop_product: {
        ids,
      },
    }
    return this.api().post(
      `shops/${shopId}/integrations/prestashop/import_products`,
      data
    )
  }

  static apiConfig = {
    actions: {
      fetchAll(shopId) {
        return this.get(`/shops/${shopId}/integrations`)
      },
    },
  }
}
