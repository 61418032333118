import { Database } from "@vuex-orm/core";
import Brand from "@/models/Brand";
import Newsletter from "@/models/Newsletter";
import LocalBusiness from "@/models/LocalBusiness";
import ContactForm from "@/models/ContactForm";
import Currency from "@/models/Currency";
import VatTax from "@/models/VatTax";
import Shop from "@/models/Shop";
import Category from "@/models/Category";
import CategoryFeature from "@/models/CategoryFeature";
import Product from "@/models/Product";
import ProductVariantPrice from "@/models/ProductVariantPrice";
import ProductVariant from "@/models/ProductVariant";
import ProductPictures from "@/models/ProductPicture";
import ProductFeature from "@/models/ProductFeature";
import ProductFeatureValueList from "@/models/ProductFeatureValueList";
import ProductFeatureValueString from "@/models/ProductFeatureValueString";
import ProductFeatureValueNumber from "@/models/ProductFeatureValueNumber";
import ProductCategory from "@/models/ProductCategory";
import ProductNotification from "@/models/ProductNotification";
import ProductNotificationKind from "@/models/ProductNotificationKind";
import CmsBlock from "@/models/CmsBlock";
import CmsPage from "@/models/CmsPage";
import CmsBlockType from "@/models/CmsBlockType";
import Integration from "@/models/Integration";
import IntegrationPrestashopProduct from "@/models/IntegrationPrestashopProduct";
import BannerSlider from "@/models/BannerSlider";
import EmailSetting from "@/models/EmailSetting";
import ShopConfiguration from "@/models/ShopConfiguration";
import ConfigurationScope from "@/models/ConfigurationScope";
import SocialMediaSimpleIntegration from "@/models/SocialMediaSimpleIntegration";
import SocialMediaSimpleConfiguration from "@/models/SocialMediaSimpleConfiguration";
import Order from "@/models/Order";
import OrderItem from "@/models/OrderItem";
import OrderStatus from "@/models/OrderStatus";
import Cart from "@/models/Cart";
import Employee from "@/models/Employee";
import Company from "@/models/Company";
import {
  Feature,
  FeatureList,
  FeatureNumber,
  FeatureString
} from "@/models/Feature";
import FeatureListValue from "@/models/FeatureListValue";
import ConfigurationScopeComponent from "@/models/ConfigurationScopeComponent";
import {
  BillingAddress,
  CustomerAddress,
  DeliveryAddress
} from "@/models/CustomerAddress";
import SellDocumentType from "@/models/SellDocumentType";
import Address from "@/models/Address";
import {
  Component,
  ComponentDeliveryCourier,
  ComponentDeliveryDhl,
  ComponentDeliveryDpd,
  ComponentDeliveryInpostCourier,
  ComponentDeliveryInpostPickupPoint,
  ComponentPaymentBankWire,
  ComponentPaymentCod,
  ComponentPaymentPayPal,
  ComponentPaymentPayU,
  ComponentPaymentPrzelewy24
} from "@/models/ComponentHierarchy";
import Country from "@/models/Country";
import Customer from "@/models/Customer";
import DeliveryPickUpPoint from "@/models/DeliveryPickUpPoint";
import ProductAttachment from "@/models/ProductAttachment";
import RelatedProduct from "@/models/RelatedProduct";
import Gift from "@/models/Gift";
import ProductBestsellers from "@/models/ProductBestseller";
import NewProduct from "@/models/NewProduct";
import Discount from "@/models/Discount";
import PdfCatalog from "@/models/PdfCatalog";
import FileShare from "@/models/FileShare";

const database = new Database();

database.register(Brand);
database.register(Newsletter);
database.register(Currency);
database.register(VatTax);
database.register(Shop);
database.register(Category);
database.register(CategoryFeature);
database.register(ContactForm);
database.register(Product);
database.register(ProductVariant);
database.register(ProductVariantPrice);
database.register(ProductPictures);
database.register(ProductAttachment);
database.register(ProductFeature);
database.register(ProductFeatureValueList);
database.register(ProductFeatureValueString);
database.register(ProductFeatureValueNumber);
database.register(ProductNotification);
database.register(ProductNotificationKind);
database.register(ProductCategory);
database.register(Employee);
database.register(Feature);
database.register(FeatureList);
database.register(FeatureNumber);
database.register(FeatureString);
database.register(FeatureListValue);
database.register(CmsBlock);
database.register(CmsPage);
database.register(CmsBlockType);
database.register(Integration);
database.register(IntegrationPrestashopProduct);
database.register(BannerSlider);
database.register(SocialMediaSimpleIntegration);
database.register(SocialMediaSimpleConfiguration);
database.register(Component);
database.register(ConfigurationScope);
database.register(ShopConfiguration);
database.register(EmailSetting);
database.register(ConfigurationScopeComponent);
database.register(Cart);
database.register(Order);
database.register(OrderItem);
database.register(OrderStatus);
database.register(CustomerAddress);
database.register(DeliveryAddress);
database.register(BillingAddress);
database.register(SellDocumentType);
database.register(Address);
database.register(ComponentDeliveryCourier);
database.register(ComponentDeliveryDhl);
database.register(ComponentDeliveryDpd);
database.register(ComponentDeliveryInpostCourier);
database.register(ComponentDeliveryInpostPickupPoint);
database.register(ComponentPaymentBankWire);
database.register(ComponentPaymentCod);
database.register(ComponentPaymentPayPal);
database.register(ComponentPaymentPayU);
database.register(ComponentPaymentPrzelewy24);
database.register(Country);
database.register(LocalBusiness);
database.register(Customer);
database.register(DeliveryPickUpPoint);
database.register(RelatedProduct);
database.register(Company);
database.register(Gift);
database.register(ProductBestsellers);
database.register(NewProduct);
database.register(Discount);
database.register(PdfCatalog);
database.register(FileShare);


export default database;
