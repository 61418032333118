import BaseModel from './BaseModel'

export default class ConfigurationScope extends BaseModel {
  static entity = 'configuration_scopes'
  static modelPath = 'configuration_scopes'
  static formKey = 'configuration_scope'

  static fields() {
    return {
      id: this.uid(),
      name: this.string(''),
      symbol: this.string(''),
      description: this.string(''),
    }
  }

  static fetchAll() {
    return this.api().get(`${this.modelPath}`)
  }
}
