import BaseModel from './BaseModel'
import { keysToSnake } from '~/helpers/caseConverter'

export default class Integration extends BaseModel {
  static entity = 'integration'
  static modelPath = 'integrations'
  static formKey = 'integration'
  static apiConfig = {
    actions: {
      fetchAll(shopId) {
        return this.get(`/shops/${shopId}/integrations`)
      },
    },
  }

  static fields() {
    return {
      id: this.uid(),
      name: this.string(''),
      symbol: this.string(''),
      configuration_frontend: this.attr(),
      configuration_backend: this.attr(),
      active: this.boolean(false),
    }
  }

  static create(shopId, form) {
    const data = {
      integration: form,
    }
    return this.api().post(`/shops/${shopId}/integrations`, data)
  }

  static remove(shopId, integrationId) {
    return this.api().delete(`/shops/${shopId}/integrations/${integrationId}`, {
      delete: integrationId,
    })
  }

  static getById(shopId, integrationId) {
    return this.api().get(`shops/${shopId}/integrations/${integrationId}`)
  }

  static setById(shopId, integrationId, form) {
    // return this.api().put(`shops/${shopId}/integrations/${integrationId}`, form)
    const data = {}
    data[this.formKey] = keysToSnake(form)
    return this.api().put(
      `shops/${shopId}/${this.modelPath}/${integrationId}`,
      data
    )
  }

  static enableById(shopId, integrationId) {
    return this.api().put(
      `shops/${shopId}/integrations/${integrationId}/enable`
    )
  }

  static disableById(shopId, integrationId) {
    return this.api().put(
      `shops/${shopId}/integrations/${integrationId}/disable`
    )
  }
}
