import { Model } from '@vuex-orm/core'
import Product from './Product'

export default class ProductAttachment extends Model {
  static entity = 'product_attachments'

  static fields() {
    return {
      id: this.uid(),
      product_id: this.number(0),
      position: this.number(0),
      name: this.string(''),
      url: this.string(''),
      product: this.belongsTo(Product, 'product_id'),
    }
  }

  static remove(shopId, productId, productAttachmentId) {
    return this.api().delete(
      `/shops/${shopId}/products/${productId}/product_attachments/${productAttachmentId}`,
      {
        delete: productAttachmentId,
      }
    )
  }

  static setById(shopId, productId, productAttachmentId, form) {
    const data = {
      product_attachment: form,
    }
    return this.api().put(
      `/shops/${shopId}/products/${productId}/product_attachments/${productAttachmentId}`,
      data
    )
  }

  static setPosition(shopId, productId, productAttachmentId, position) {
    const data = {
      product_attachment: {
        position,
      },
    }
    return this.api().put(
      `/shops/${shopId}/products/${productId}/product_attachments/${productAttachmentId}/set_position`,
      data
    )
  }


  static apiConfig = {
    actions: {
      fetchForProductId(shopId, productId) {
        return this.get(
          `/shops/${shopId}/products/${productId}/product_attachments`
        )
      },
      upload(shopId, productId, attachments, progressHandlerr) {
        const formData = new FormData()
        for (let i = 0; i < attachments.length; i++) {
          const file = attachments[i]
          formData.append('product_attachments[]', file)
        }
        const config = {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: progressHandlerr,
        }
        return this.post(
          `/shops/${shopId}/products/${productId}/product_attachments`,
          formData,
          config
        )
      },
    },
  }
}
