import Vue from 'vue'

const mixin = {
  methods: {
    initForm(formName, model) {
      const keys = Object.keys(this[formName])
      const copiedModel = JSON.parse(JSON.stringify(model))
      console.log('initForm', copiedModel)
      this[formName] = Object.fromEntries(
        Object.entries(copiedModel).filter(([key]) => keys.includes(key))
      )
    },
    saveFailed(err) {
      const errorCode = err?.response?.status ?? ''
      this.$buefy.toast.open({
        message: `Błąd ${errorCode} podczas zapisu`,
        type: 'is-danger',
        duration: 5000,
        queue: false,
      })
    },
    saveFormSuccess() {
      this.actionSuccess('Zmiany zapisane')
      this?.$refs?.observer?.reset()
    },
    saveFormFailed(error) {
      const errorData = error?.response?.data
      const observerRefs = this?.$refs?.observer?.refs
      if (errorData && observerRefs) {
        const observerKeys = Object.keys(observerRefs)
        const errorKeys = Object.keys(errorData)
        const isResponseSimilarToRefs = errorKeys.some((element) =>
          observerKeys.includes(element)
        )
        if (isResponseSimilarToRefs) {
          this.actionFailed('Popraw pola zaznaczone w formularzu')
        } else this.saveFailed(error)

        const setErrors = this.$refs.observer.setErrors
        if (typeof setErrors === 'function') {
          setErrors(errorData)
        }
      } else this.saveFailed(error)
    },
  },
}

if (!Vue.generic_form_mixin) {
  Vue.generic_form_mixin = true
  Vue.mixin(mixin)
}
