import BaseModel from '@/models/BaseModel'
import {
  ComponentDeliveryCourier,
  ComponentDeliveryDhl,
  ComponentDeliveryDpd,
  ComponentDeliveryInpostCourier,
  ComponentDeliveryInpostPickupPoint,
  ComponentPaymentBankWire,
  ComponentPaymentCod,
  ComponentPaymentPayPal,
  ComponentPaymentPayU,
  ComponentPaymentPrzelewy24,
} from '@/models/ComponentHierarchy'

export class Component extends BaseModel {
  static entity = 'components'
  static modelPath = 'components'
  static formKey = 'component'

  static types() {
    return {
      Component,
      ComponentDeliveryCourier,
      ComponentDeliveryDhl,
      ComponentDeliveryDpd,
      ComponentDeliveryInpostCourier,
      ComponentDeliveryInpostPickupPoint,
      ComponentPaymentBankWire,
      ComponentPaymentCod,
      ComponentPaymentPayPal,
      ComponentPaymentPayU,
      ComponentPaymentPrzelewy24,
    }
  }

  static fields() {
    return {
      id: this.uid(),
      name: this.string(''),
      description: this.string(''),
      module_params: this.attr(null),
      type: this.string(''),
      icon: this.string(''),
      allow_multiple_instances: this.boolean(false),
      configuration_type: this.string('simple'),
    }
  }
}

export default Component
