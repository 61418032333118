import BaseModel from './BaseModel'

export default class ContactForm extends BaseModel {
  static entity = 'contact_forms'
  static modelPath = 'contact_forms'
  static formKey = 'contact_form'

  static fields() {
    return {
      id: this.uid(),
      name: this.string(''),
      email: this.string(''),
      subject: this.string(''),
      message: this.string(''),
      shopp_id: this.number(null),
      created_at: this.attr(),
    }
  }
}
