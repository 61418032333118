import BaseModel from "@/models/BaseModel";

export default class Gift extends BaseModel {
  static entity = "gifts";
  static modelPath = "gifts";
  static formKey = "gift";

  static fields() {
    return {
      id: this.uid(),
      name: this.string(""),
      symbol: this.string(""),
      description: this.string(""),
      min_order_value: this.number(0),
      max_order_value: this.number(0),
      is_active: this.boolean(true),
      image_path: this.string("")
    };
  }

  get imageExists() {
    return (
      this.image_path !== undefined &&
      this.image_path !== null &&
      this.image_path.length > 0
    );
  }

  get getImageUrl() {
    if (this.image_path) {
      return this.baseImageUrl + this.image_path;
    } else {
      return "";
    }
  }

  get baseImageUrl() {
    return this.$store().getters["shop/imageBaseUrl"];
  }

  static create(shopId, form) {
    const data = {
      gift: form
    };
    return this.api().post(`/shops/${shopId}/gifts`, data);
  }

  static remove(shopId, giftId) {
    return this.api().delete(`/shops/${shopId}/gifts/${giftId}`, {
      delete: giftId
    });
  }

  static getById(shopId, giftId) {
    return this.api().get(`shops/${shopId}/gifts/${giftId}`);
  }

  static setById(shopId, giftId, form) {
    return this.api().put(`shops/${shopId}/gifts/${giftId}`, form);
  }

  static apiConfig = {
    actions: {
      fetchAll(shopId) {
        return this.get(`/shops/${shopId}/gifts`);
      }
    }
  };
}
