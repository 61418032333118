export const state = () => ({
  roles: [],
  userId: null,
  csrfToken: null,
})

export const getters = {}

export const mutations = {
  CSRF_TOKEN_SET(state, payload) {
    state.csrfToken = payload.csrfToken
  },
  CSRF_TOKEN_CLEAR(state) {
    state.csrfToken = null
  },
}

export const actions = {
  // works onlny on client side
  signInWithCookies({ commit }, payload) {
    // debugger
    return this.$auth
      .loginWith('local', {
        data: {
          email: payload.user,
          password: payload.password,
          method: 'header',
        },
      })
      .then(function (result) {
        const csrfToken = result.data.csrf
        commit('CSRF_TOKEN_SET', { csrfToken })
      })
      .catch((err) => {
        return err
      })
  },
  signInWithHeader({ commit, dispatch }, payload) {
    // debugger
    return this.$auth
      .loginWith('local', {
        data: {
          email: payload.email,
          password: payload.password,
          method: 'header',
        },
      })
      .then(function (result) {
        const csrfToken = result.data.csrf
        commit('CSRF_TOKEN_SET', { csrfToken })
        dispatch('shop/initState', null, { root: true })
      })
  },
  async logUserOut({ commit, dispatch }) {
    // console.log('logUserOut')
    await this.$auth
      .logout()
      .then(() => {
        commit('CSRF_TOKEN_CLEAR')
        dispatch('shop/clearStore', null, { root: true })
      })
      .catch((err) => {
        return err
      })
  },
}
