import { Model } from '@vuex-orm/core'
import CmsPage from './CmsPage'
import CmsBlockType from './CmsBlockType'

export default class CmsBlock extends Model {
  static entity = 'cms_blocks'

  static fields() {
    return {
      id: this.number(),
      cms_page_id: this.number(null).nullable(),
      position: this.number(null).nullable(),
      cms_block_type_id: this.number(null).nullable(),
      content_text: this.string(),
      content_string: this.string(),
      cms_page: this.belongsTo(CmsPage, 'cms_page_id'),
      cms_block_type: this.belongsTo(CmsBlockType, 'cms_block_type_id'),
    }
  }

  get getContent() {
    switch (this.cms_block_type.name) {
      case 'string':
        return this.content_string
      case 'text':
        return this.content_text
      default:
        return 'cms bloc type not found'
    }
  }

  static addBlock(shopId, cmsPageId, blockTypeId) {
    const form = {
      cms_block: {
        cms_block_type_id: blockTypeId,
        value: '',
      },
    }
    return this.api().post(
      `shops/${shopId}/cms_pages/${cmsPageId}/cms_blocks`,
      form
    )
  }

  static remove(shopId, cmsPageId, cmsBlockId) {
    return this.api().delete(
      `shops/${shopId}/cms_pages/${cmsPageId}/cms_blocks/${cmsBlockId}`,
      {
        delete: cmsBlockId,
      }
    )
  }

  static apiConfig = {
    actions: {
      fetchAll() {
        return this.get('cms_pages')
      },
    },
  }
}
