import BaseModel from "./BaseModel";
import ProductVariant from "@/models/ProductVariant";

export default class ProductVariantPrice extends BaseModel {
  static entity = "product_variant_prices";
  static modelPath = "product_variant_prices";
  static formKey = "product_variant_price";

  static fields() {
    return {
      id: this.uid(),
      quantity: this.attr(null),
      price_net: this.number(0),
      promo_price_net: this.number(0),
      sale: this.attr(null),
      product_variant_id: this.attr(null),
      product_variant: this.belongsTo(ProductVariant, "product_variant_id")

    };
  }

  static add(shopId, productId, productVariantId, productVariantPriceData) {
    const data = {
      product_variant_price: productVariantPriceData
    };
    return this.api().post(`/shops/${shopId}/products/${productId}/product_variants/${productVariantId}/product_variant_prices`, data)
      .then((data) => {
        // console.log(data.response.data.product_features);
        // ProductFeature.insertProductFeaturesValues(data.response.data.product_features);
        return data;
      });
    ;
  }

  static remove(shopId, productId, productVariantId, productVariantPriceId) {
    return this.api().delete(`/shops/${shopId}/products/${productId}/product_variants/${productVariantId}/product_variant_prices/${productVariantPriceId}`, {
      delete: productVariantPriceId
    });
  }
}
