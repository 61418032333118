import BaseModel from '@/models/BaseModel'
import Address from '@/models/Address'
import DeliveryPickUpPointProvider from '@/models/DeliveryPickUpPointProvider'

export default class DeliveryPickUpPoint extends BaseModel {
  static entity = 'delivery_pick_up_points'
  static modelPath = 'delivery_pick_up_points'
  static formKey = 'delivery_pick_up_point'

  static fields() {
    return {
      id: this.uid(),
      pick_up_point_provider_id: this.number(null),
      point_code: this.string(''),
      point_name: this.string(''),
      address_id: this.number(null),
      address: this.belongsTo(Address, 'address_id'),
      pick_up_point_provider: this.belongsTo(
        DeliveryPickUpPointProvider,
        'pick_up_point_provider_id'
      ),
    }
  }

  static getPickUpPointListQuery(data) {
    const config = {
      save: false,
    }
    const dataConfig = { ...data, ...config }
    return this.api()
      .get('pick_up_points', dataConfig)
      .then((response) => {
        return DeliveryPickUpPoint.processResponsePickUpPoints(response)
      })
      .catch((e) => {
        console.error(e)
      })
  }

  static processResponsePickUpPoints(response) {
    const pickUpPoints = response.response.data

    const pickUpPointsIds = pickUpPoints.map((p) => {
      return p.id
    })
    DeliveryPickUpPoint.insert({ data: pickUpPoints })
    return {
      pickUpPointsIds,
    }
  }
}
