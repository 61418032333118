import BaseModel from "./BaseModel";
import Product from "./Product";
import ProductFeature from "@/models/ProductFeature";
import ProductFeatureValueList from "@/models/ProductFeatureValueList";
import ProductFeatureValueNumber from "@/models/ProductFeatureValueNumber";
import ProductFeatureValueString from "@/models/ProductFeatureValueString";
import ProductVariantPrice from "@/models/ProductVariantPrice";
import FeatureListValue from "@/models/FeatureListValue";
import { Feature } from "@/models/Feature";

export default class ProductVariant extends BaseModel {
  static entity = "product_variants";
  static modelPath = "product_variants";
  static formKey = "product_variant";

  static fields() {
    return {
      id: this.uid(),
      symbol: this.attr(null),
      ean: this.attr(null),
      availability_status_id: this.attr(null),
      product_id: this.attr(null),
      product: this.belongsTo(Product, "product_id"),
      product_features: this.hasMany(ProductFeature, "product_variant_id"),
      product_variant_prices: this.hasMany(ProductVariantPrice, "product_variant_id")

    };
  }

  // get feature name with its value
  get getCombinationValues() {
    const combination = [];
    const productFeatures = ProductFeature.query()
      .where("product_id", this.product_id)
      .where("product_variant_id", this.id)
      .with("feature")
      .get();
    productFeatures.forEach((productFeature) => {
      const feature = productFeature.feature;
      let value = null;
      switch (productFeature.feature_settable_type) {
        case "ProductFeatureValueList": {
          const valueId = ProductFeatureValueList.find(productFeature.feature_settable_id)?.feature_list_value_id;
          const flv = FeatureListValue.find(valueId);
          value = flv?.value;
        }
          break;
        case "ProductFeatureValueNumber":
          value = ProductFeatureValueNumber.find(productFeature.feature_settable_id)?.value;
          break;
        case "ProductFeatureValueString":
          value = ProductFeatureValueString.find(productFeature.feature_settable_id)?.value;
          break;
        default:
          // rise error
          throw new Error("Unknown feature_settable_type");
      }
      combination.push(
        {
          featureName: feature.name,
          value
        }
      );
    });
    return combination;
  }

  static getCombinationUsingCombinationData(combinationData) {
    const combination = [];
    combinationData.forEach((data) => {
      let valueType = null;
      let value = null;
      // we only have feature no productFeature
      const feature = Feature.find(data.featureId);
      switch (feature.type) {
        case "FeatureString":
          valueType = "String";
          value = data.value;
          break;
        case "FeatureList":
          valueType = "Id";
          value = data.featureListValueId;
          break;
        case "FeatureNumber":
          valueType = "Number";
          value = data.value;
          break;
        default:
          // rise error
          throw new Error("Unknown feature type");
      }

      combination.push({
        featureId: feature.id,
        valueType,
        value
      });
    });

    return combination;
  }

  get getCombination() {
    // console.log("getCombination for symbol", this.symbol);
    const combination = [];
    // variant.combination_data;
    const productFeatures = ProductFeature.query()
      .where("product_id", this.product_id)
      .where("product_variant_id", this.id)
      .get();
    // console.log("productFeatures for", productFeatures);
    productFeatures.forEach((productFeature) => {
        let value = null;
        let valueType = null;
        switch (productFeature.feature_settable_type) {
          case "ProductFeatureValueList":
            value = ProductFeatureValueList.find(productFeature.feature_settable_id)?.feature_list_value_id;
            valueType = "Id";
            break;
          case "ProductFeatureValueNumber":
            value = ProductFeatureValueNumber.find(productFeature.feature_settable_id)?.value;
            valueType = "Number";
            break;
          case "ProductFeatureValueString":
            value = ProductFeatureValueString.find(productFeature.feature_settable_id)?.value;
            valueType = "String";
            break;
          default:
            // rise error
            throw new Error("Unknown feature_settable_type");
        }
        combination.push(
          {
            featureId: productFeature.feature_id,
            valueType,
            value
          }
        );
      }
    );
    return combination;

  }


  static fetchForProductId(shopId, productId) {
    return this.api().get(`/shops/${shopId}/products/${productId}/product_variants`);
  }

  static remove(shopId, productId, id) {
    return this.api().delete(`/shops/${shopId}/products/${productId}/product_variants/${id}`, {
      delete: id
    });
  }

  static add(shopId, productId, form) {
    const data = {
      product_variant: form
    };
    return this.api().post(`/shops/${shopId}/products/${productId}/product_variants`, data)
      .then((data) => {
        console.log(data.response.data.product_features);
        ProductFeature.insertProductFeaturesValues(data.response.data.product_features);
        return data;
      });
    ;
  }

}
