import Product from "./Product";
import { Feature } from "./Feature";
import ProductVariant from "./ProductVariant";
import ProductFeatureValueList from "./ProductFeatureValueList";
import ProductFeatureValueNumber from "./ProductFeatureValueNumber";
import ProductFeatureValueString from "./ProductFeatureValueString";
import BaseModel from "@/models/BaseModel";

export default class ProductFeature extends BaseModel {
  static entity = "product_features";
  static modelPath = "product_features";
  static formKey = "product_feature";

  static fields() {
    return {
      id: this.uid(),
      product_id: this.number(0),
      feature_id: this.number(0),
      position: this.number(0),
      feature_settable_id: this.number(null),
      feature_settable_type: this.string(null),
      feature_settable: this.morphTo(
        "feature_settable_id",
        "feature_settable_type"
      ),
      enable_variants: this.boolean(false),
      product_variant_id: this.number(null),
      product: this.belongsTo(Product, "product_id"),
      feature: this.belongsTo(Feature, "feature_id"),
      product_variants: this.hasMany(ProductVariant, "product_variant_id")
    };
  }

  static remove(shopId, productId, productFeatureId) {
    return this.api().delete(
      `/shops/${shopId}/products/${productId}/product_features/${productFeatureId}`,
      {
        delete: productFeatureId
      }
    );
  }

  static setById(shopId, productId, productFeatureId, form) {
    const data = {
      product_feature: form
    };
    return this.api().put(
      `/shops/${shopId}/products/${productId}/product_features/${productFeatureId}`,
      data
    );
  }

  static setFeatureVariants(shopId, productId, productFeatureId, form) {
    const data = {
      product_feature: form
    };

    // we remove ProductVariants since all wil be deleted if exists for productId
    ProductVariant.deleteAll();
    ProductFeature.delete((pf) => {
      console.log(pf.product_variant_id, pf.product_id, productId);
      return pf.product_variant_id > 0 && pf.product_id === productId;
    });


    return this.api().put(
      `/shops/${shopId}/products/${productId}/product_features/${productFeatureId}/change_variants`,
      data
    );
  }


  static add(shopId, productId, featureId) {
    const data = {
      product_feature: {
        feature_id: featureId
      }
    };
    return this.api().post(
      `/shops/${shopId}/products/${productId}/product_features`,
      data
    );
  }

  // static setById(shopId, productId, productPictureId, form) {
  //   const data = {
  //     product_picture: form
  //   }
  //   return this.api().put(
  //     `/shops/${shopId}/products/${productId}/product_pictures/${productPictureId}`,
  //     data
  //   )
  // }

  static useDefaultCategoryFeatures(shopId, productId) {
    return this.api().post(
      `/shops/${shopId}/products/${productId}/product_features/use_default_category_features`
    );
  }

  static setPosition(shopId, productId, productFeatureId, position) {
    const data = {
      product_feature: {
        position
      }
    };
    return this.api().put(
      `/shops/${shopId}/products/${productId}/product_features/${productFeatureId}/set_position`,
      data
    );
  }

  static fetchForProductId(shopId, productId) {
    return this.api()
      .get(`/shops/${shopId}/products/${productId}/product_features`, {
        save: true
      })
      .then(({ response }) => {
        this.insertProductFeaturesValues(response.data);
      });
  }


  static insertProductFeaturesValues(productFeatureValues) {
    const productFeaturesValuesList = [];
    const productFeaturesValuesNumbers = [];
    const productFeaturesValuesStrings = [];
    productFeatureValues.forEach((pf) => {
      console.log("Processing ", pf, pf.feature_settable, pf.feature_settable_type);
      switch (pf.feature_settable_type) {
        case "ProductFeatureValueList":
          productFeaturesValuesList.push(pf.feature_settable);
          break;
        case "ProductFeatureValueNumber":
          productFeaturesValuesNumbers.push(pf.feature_settable);
          break;
        case "ProductFeatureValueString":
          productFeaturesValuesStrings.push(pf.feature_settable);
          break;
        default:
          break;
      }
    });
    if (productFeaturesValuesList.length > 0) {
      console.log("inserting", productFeaturesValuesList);
      ProductFeatureValueList.insertOrUpdate({
        data: productFeaturesValuesList
      });
    }
    if (productFeaturesValuesNumbers.length > 0) {
      ProductFeatureValueNumber.insertOrUpdate({
        data: productFeaturesValuesNumbers
      });
    }
    if (productFeaturesValuesStrings.length > 0) {
      ProductFeatureValueString.insertOrUpdate({
        data: productFeaturesValuesStrings
      });
    }
  }

}
