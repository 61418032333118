import BaseModel from '@/models/BaseModel'

export default class Currency extends BaseModel {
  static entity = 'currencies'
  static modelPath = 'currencies'
  static formKey = 'currency'

  static fields() {
    return {
      id: this.uid(),
      name: this.string(''),
      symbol: this.string(''),
    }
  }
}
