import ConfirmDialog from '@/mixins/confirmDiscard'

export default (context) => {
  const app = context.app
  app.router.beforeEach(async (_to, _from, next) => {
    let allowRouteChange = true
    const isFormDirty = app.store.getters['shop/unsavedObjectData']
    if (isFormDirty) {
      allowRouteChange = await ConfirmDialog()
    }
    next(allowRouteChange)
  })
}
