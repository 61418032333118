import BaseModel from '@/models/BaseModel'

export default class Brand extends BaseModel {
  static entity = 'brands'
  static modelPath = 'brands'
  static formKey = 'brand'

  static fields() {
    return {
      id: this.uid(),
      name: this.string(''),
      image_path: this.string(''),
    }
  }

  get imageExists() {
    return (
      this.image_path !== undefined &&
      this.image_path !== null &&
      this.image_path.length > 0
    )
  }

  get getImageUrl() {
    if (this.image_path) {
      return this.baseImageUrl + this.image_path
    } else {
      return ''
    }
  }

  get baseImageUrl() {
    return this.$store().getters['shop/imageBaseUrl']
  }

  static create(shopId, form) {
    const data = {
      brand: form,
    }
    return this.api().post(`/shops/${shopId}/brands`, data)
  }

  static remove(shopId, brandId) {
    return this.api().delete(`/shops/${shopId}/brands/${brandId}`, {
      delete: brandId,
    })
  }

  static getById(shopId, brandId) {
    return this.api().get(`shops/${shopId}/brands/${brandId}`)
  }

  static setById(shopId, brandId, form) {
    return this.api().put(`shops/${shopId}/brands/${brandId}`, form)
  }

  static apiConfig = {
    actions: {
      fetchAll(shopId) {
        return this.get(`/shops/${shopId}/brands`)
      },
    },
  }
}
